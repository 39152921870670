.MuiTypography-root.tag {
  padding: 2px 7px;
  background-color: #8fc31f;
  border-radius: 3px;
  color: #fff;
  margin-left: 10px;
  white-space: nowrap;
}

.required {
  color: rgba(242, 39, 53, 1);
  margin-left: 10px;
}

.MuiTypography-root.label {
  font-family: "M PLUS 1p", sans-serif;
  line-height: unset;
  color: rgba(22, 47, 80, 1);
  white-space: nowrap;
}

.MuiGrid-container.inline-block {
  display: inline-block;
}
