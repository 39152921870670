.MuiTypography-root.grey-light {
    color: #b1b1b1;
}

.MuiTypography-root.grey-deep {
    color: #808080;
    font-weight: 100;
}

.white-space-normal {
    white-space: normal;
}

button:focus {
    background-color: transparent;
}

.pagination button {
    border-radius: 0;
}

.pagination button.MuiFlatPagination-rootCurrent,
.pagination button.MuiFlatPagination-rootCurrent:hover {
    background: rgba(22, 47, 80, 1);
}

.pagination button.MuiFlatPagination-rootCurrent>span {
    color: #fff;
}

.sites-page {
    height: 100%;
    min-height: calc(100vh - 76px);
}

.sites-left {
    min-width: 250px;
}

.sites-right {
    max-width: 100%;
    width: 100%;
}

.sites-left-tab {
    background-color: rgba(240, 240, 240, 1);
    height: 100%;
}

.MuiListItem-button.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.search-image {
    max-width: 300px;
    width: 100%;
}

button.search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

div.search-image input:not(.browser-default) {
    border-bottom: none;
    margin-bottom: 0;
    background-color: rgba(240, 240, 240, 1);
    height: auto;
    border-radius: 3px;
    padding: 10px 45px 10px 15px;
}

div.search-image input:not(.browser-default):focus:not([readonly]) {
    background-color: rgba(240, 240, 240, 1);
    border-bottom: none;
    box-shadow: none;
}

.padding-content {
    padding: 10px 40px 10px 50px;
}

.site-list {
    min-width: 1200px;
}

.sites-table,
.sites-table-sp {
    box-shadow: none;
}

.sites-table td,
.sites-table-sp td {
    word-break: break-word;
}

.site-list .sites-table td.MuiTableCell-sizeSmall {
    padding: 3px;
}

.table-head {
    background-color: rgba(22, 47, 80, 1);
}

.table-head th {
    border-radius: 0;
    padding-top: 12px;
    padding-bottom: 12px;
}

td.padding-first-td,
th.padding-first-th {
    padding-left: 20px;
}

.site-list td.padding-first-td,
th.padding-first-th {
    padding-left: 15px;
}

td.MuiTableCell-root.padding-last-td {
    padding-right: 40px;
}

button.button-log,
li.button-log {
    box-shadow: none;
    background-color: #8fc31f;
    border-radius: 3px;
    height: 40px;
    padding: 0 10px;
    white-space: nowrap;
}

button.button-log-sp,
button.button-log-sp:hover {
    box-shadow: none;
    background-color: #8fc31f;
}

button.button-log-sp-disable,
button.button-log-sp-disable:hover,
button.button-logWalk-sp-disable,
button.button-logWalk-disable:hover {
    box-shadow: none;
    background-color: #808080 !important;
}

li.button-log {
    border-radius: 50px;
    padding: 0 20px;
    width: 190px;
    height: 30px;
    font-family: "M PLUS 1p", sans-serif;
}

li.button-log:hover {
    background-color: #8fc31f;
}

li.Mui-selected.button-log,
li.Mui-selected.button-log:hover {
    background-color: #808080;
}

button.button-log-robot {
    box-shadow: none;
    background-color: #8fc31f;
    border-radius: 3px;
    height: 40px;
    padding: 0 10px;
    white-space: nowrap;
    cursor: default;
    text-transform: none;
}

button.button-log-robot:hover {
    background-color: #8fc31f;
}

button.button-log-disalbe {
    box-shadow: none;
    background-color: #808080;
    border-radius: 3px;
    width: 190px;
    height: 40px;
    padding: 0 10px;
    white-space: nowrap;
    cursor: default;
}

button.button-log-disalbe:hover {
    background-color: #808080;
}

button.button-log:focus {
    box-shadow: none;
}

.icon-margin {
    margin-right: 10px;
}

svg.icon-small-size {
    width: 0.8em;
}

.arrow-up {
    margin-top: -3px;
}

button.button-add {
    color: rgba(22, 47, 80, 1);
}

button.button-add-new {
    padding: 4px 10px 4px 6px;
    color: white;
    background-color: rgba(22, 47, 80, 1);
    height: 40px;
}

button.button-add:focus {
    background-color: transparent;
}

button.button-add-new:focus {
    background-color: rgba(22, 47, 80, 1) !important;
}

button.button-add-new:hover {
    background-color: rgba(22, 47, 80, 1) !important;
}

.display-none {
    display: none !important;
}

div.input-reset {
    margin-top: 20px;
}

.input-reset input:not(.browser-default) {
    border-bottom: none;
    margin-bottom: 0;
    padding: 23px 12px 10px;
    height: auto;
    font-size: 0.9rem;
}

.input-reset input[type="text"]:focus {
    background-color: transparent;
}

.input-reset input:not(.browser-default):focus:not([readonly]) {
    box-shadow: none;
    border-bottom: none;
}

.input-reset .MuiFilledInput-underline:before,
.input-reset .MuiFilledInput-underline:hover:before {
    border-color: rgba(22, 47, 80, 1);
}

.input-reset>label {
    color: rgba(22, 47, 80, 1);
}

.dialog-sign-up {
    padding: 15px;
}

.sites-left .sites-left-item span,
.content-wrap input,
.MuiTypography-root.dialog-title,
.input-reset>label,
button.dialog-button,
button.button-add,
.search-image input,
.table-head th,
.table-body td,
button.button-log {
    font-family: "M PLUS 1p", sans-serif;
}

.MuiTypography-root.dialog-title {
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content-wrap {
    max-width: 450px;
    width: 100%;
    display: block;
    margin: 0 auto;
}

.width-lg {
    max-width: 900px;
}

.row {
    margin: 0 -15px;
}

.col {
    padding-left: 15px;
    padding-right: 15px;
}

.pt-100 {
    padding-top: 100%;
}

.margin-left {
    margin-left: 10px;
}

button.dialog-button {
    background-color: rgba(22, 47, 80, 1);
    color: #fff;
    transition: 0.3s;
    max-width: 150px;
    width: 100%;
}

button.dialog-button:hover {
    color: rgba(22, 47, 80, 1);
}

div.dialog-action {
    justify-content: center;
    margin-top: 35px;
}

.error {
    color: red;
    display: inline-block;
    width: 100%;
}

.field-name-info {
    width: 100px;
    display: inline-block;
}

.MuiTypography-root.font-family {
    font-family: "M PLUS 1p", sans-serif;
}

.MuiTypography-root.mb-25,
.MuiGrid-root.mb-25 {
    margin-bottom: 25px;
}

.MuiTypography-root.mb-10,
.MuiGrid-root.mb-10 {
    margin-bottom: 10px;
}

.MuiTypography-root.mb-15,
.MuiGrid-root.mb-15 {
    margin-bottom: 15px;
}

.MuiGrid-root.mt-10 {
    margin-top: 10px;
}

.MuiGrid-root.mt-25 {
    margin-top: 25px;
}

.MuiGrid-root.mt-25-sp {
    margin-top: 25px;
    padding: 15px;
}

.MuiGrid-root.pd-15 {
    padding: 15px;
}

.MuiGrid-root.mb-40-sp {
    margin-bottom: 40px;
}

button.more-vert-btn {
    box-shadow: none;
    background-color: transparent;
}

.dialog-min-height {
    min-height: 400px;
}

.logwalkViewerDialogBg {
    background-color: #fffffff2 !important;
}

.MuiFab-root.back-btn {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    position: absolute;
    bottom: 0;
    background-color: transparent;
    border-top: 1px solid #f0f0f0;
    color: rgba(22, 47, 80, 1);
    z-index: 20;
}

.MuiFab-root.back-btn:focus {
    box-shadow: none;
}

.MuiPaper-root.paper-image {
    background-color: transparent;
    box-shadow: none;
}

.title-block {
    width: calc(100% - 220px);
    display: flex;
}

.MuiDialogContent-root.image-content-dialog {
    overflow: hidden;
    position: relative;
    display: flex;
    margin-top: 60px;
    align-items: center;
    justify-content: space-between;
}

.drawing-dialog-content .MuiDialogContent-root.image-content-dialog {
    height: 60vh;
}

.image-wrap-content {
    overflow-y: auto;
    width: 100%;
    text-align: center;
    z-index: 1;
}

.image-wrap-content img {
    max-width: 100%;
    max-height: 100%;
}

.image-content-dialog .button-group-dialog {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.image-content-dialog .button-download {
    position: fixed;
    top: 93%;
    transform: translateY(-50%);
    right: 25px;
}

.content-dialog .button-prev-dialog {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.content-dialog .button-next-dialog {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

div.padding-dialog-image {
    padding: 10px 60px;
}

div.padding-dialog-drawing {
    display: flex;
    padding: 10px 0px 5px;
}

.title-image {
    position: fixed;
    top: 0;
    margin-top: 32px !important;
    width: 100%;
    max-width: 1280px;
}

button.reset-button-image {
    background-color: transparent;
    box-shadow: none;
}

button.reset-button-image:hover {
    background-color: transparent;
}

.table-row {
    height: 50px;
}

.table-row:hover {
    background-color: #d6d6d6;
}

.img-contact {
    height: 60px;
    margin-right: 25px;
}

.list-button {
    display: flex;
}

.item-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.MuiDialog-paper.image-dialog-edit {
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;
    max-width: none;
    max-height: none;
}

.MuiDialogTitle-root.header-dialog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
}

.header-dialog button {
    background-color: transparent;
    box-shadow: none;
    color: #808080;
}

.header-dialog p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 80px);
}

.MuiDialogContent-root.content-dialog {
    padding: 100px 0;
    background-color: #000;
    position: relative;
}

.content-dialog img {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translateX(-50%) translateY(-50%);
}

.MuiTypography-root.section-title {
    font-size: 1.5rem;
}

.MuiCard-root.robot-box {
    box-shadow: none;
    width: 100%;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    border-radius: 10px;
    margin-bottom: 20px;
}

.robot-box .MuiCardContent-root.robot-info {
    padding: 0;
    padding-left: 30px;
    width: 100%;
}

.robot-box .robot-image {
    padding-bottom: 75%;
    align-items: center;
    height: 200;
    width: 100%;
    background-size: contain;
}

.robot-box .wrap-image {
    width: 40%;
    display: inherit;
    align-items: center;
}

.MuiTableRow-root.mb-20 {
    padding-bottom: 20px;
}

.MuiFab-root.full-width {
    width: 100%;
    margin: 10px;
}

.table-body .width-30 {
    width: 30%;
    max-width: 30%;
}

.table-body .width-40 {
    width: 40%;
    max-width: 40%;
}

div.dialog-action-sp {
    padding: 0 24px;
}

.dialog-action-sp button {
    max-width: 100%;
}

.w100 {
    width: 100%;
}

button.pX-50 {
    padding: 0 50px;
}

div.MuiInputBase-root {
    font-family: "M PLUS 1p", sans-serif;
}

div.flex-nowrap {
    flex-wrap: nowrap;
}


/* For SiteBuilderSP */

.MuiGrid-root.header-site-sp {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-site-sp button {
    background-color: transparent;
    box-shadow: none;
}

button.button-sp {
    box-shadow: none;
    background-color: rgba(22, 47, 80, 1);
    position: fixed;
    bottom: 20px;
    z-index: 300;
}

.button-sp label {
    line-height: normal;
}

button.button-back-sp {
    left: 15px;
}

button.button-upload-sp {
    left: 50%;
    transform: translateX(-50%);
}

button.button-visibility-sp {
    right: 15px;
}

.MuiMenuItem-root.site-sp-item {
    padding-top: 15px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(224, 224, 224, 1);
}

td.info-cell-more {
    width: 25px;
}

td.info-cell-more button {
    min-width: 25px;
    width: 25px;
}

td.info-cell-more button .icon-margin {
    margin-right: 0;
}

.MuiTable-root.sites-table-sp th,
.MuiTable-root.sites-table-sp td {
    padding-left: 5px;
    padding-right: 5px !important;
}

.MuiTable-root.sites-table-sp th {
    text-align: center;
}

.MuiTable-root.sites-table-sp th:last-child,
.MuiTable-root.sites-table-sp td:last-child {
    padding-left: 0;
    padding-right: 0 !important;
}

.MuiGrid-root.item-left h5 {
    word-break: break-word;
}

button.button-logWalk {
    box-shadow: none;
    background-color: rgba(22, 47, 80, 1);
    border-radius: 3px;
    width: 200px;
    height: 40px;
    padding: 0 10px;
    white-space: nowrap;
    text-transform: none;
}
button.button-feature-flag {
    box-shadow: none;
    background-color: rgba(22, 47, 80, 1);
    text-align: left !important;
}

button.button-logWalk-sp {
    box-shadow: none;
    background-color: rgba(22, 47, 80, 1);
}

button.button-logWalk-disable {
    box-shadow: none;
    background-color: #808080;
    border-radius: 3px;
    width: 190px;
    height: 40px;
    padding: 0 10px;
    white-space: nowrap;
    cursor: default;
    text-transform: none;
}

button.button-logWalk-disable:hover {
    background-color: #808080;
}

.MuiGrid-root#primary-map,
.MuiGrid-root#primary-map-sp,
.sub-map .MuiGridListTile-tile {
    position: relative;
    text-align: center;
}

.noteText {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    touch-action: none;
    -ms-touch-action: none;
    pointer-events: none;
    justify-content: center;
    align-items: center;
    background-color: #1b2428cc !important;
    text-align: center;
    color: #fff;
    font-size: 20px;
    z-index: -1;
    opacity: 0;
    transition-duration: 0.8s
}


.noteText.show {
    transition-duration: 0.3s;
    opacity: 1;
    z-index: 2;
}

.noteText p {
    position: absolute;
    width: 95%;
}


.MuiGrid-root.sub-image {
    position: relative;
}

.MuiGrid-root.sub-map {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    background-color: #fff;
}

.marker {
    color: rgb(73, 75, 201);
    position: absolute;
    margin: -20px -11px;
    transform: translateY(47%);
    z-index: 99;
}

.marker-no-spot {
    color: rgb(73, 75, 201);
    position: absolute;
    margin: -20px -11px;
}

.marker:hover {
    cursor: pointer;
    color: rgb(250, 130, 39);
}

.marker-prevent-hover {
    color: rgb(73, 75, 201);
    position: absolute;
    margin: -20px -11px;
    transform: translateY(47%);
    z-index: 99;
}

.modal {
    width: 200px;
    height: 40px;
    border-radius: 5px;
    background-color: #fff;
    position: absolute;
    display: block;
    margin: -65px 15px;
    z-index: 100;
    font-size: 9px;
}


/* FOR PC */

.draggable-primary {
    height: calc(100vh - 155px);
    min-height: 100px;
    background-color: #efefef;
    border: solid 1px #c7c7c7;
}

.MuiGrid-root.MuiGrid-root#primary-map {
    width: 100%;
    height: calc(100vh - 500px);
    min-height: 100px;
    background-color: #efefef;
}

.background-gray-anpha95 {
    background: #e5e5e5f2;
}

.MuiGrid-root.MuiGrid-root#primary-map-dialog {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    cursor: not-allowed;
    text-align: center;
}

.MuiGrid-root.MuiGrid-root#primary-map #primary-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    overflow: hidden;
    display: inline-block;
}

.MuiGrid-root.MuiGrid-root#primary-map-dialog #primary-image-dialog {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    overflow: hidden;
    display: inline-block;
}

#primary-image-dialog.pin-spot {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: calc(60vh) !important;
    object-fit: contain;
    overflow: hidden;
    display: inline-block;
}

.MuiGrid-root.MuiGrid-root#primary-map #primary-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    overflow: hidden;
    display: inline-block;
}

.wrapper-dialog{
    position: fixed;
    width: 100vw;
    height: 600px;
    top: 0;
    left: 0;
    background-color: hsl(0deg 0% 74% / 64%);
    overflow: scroll;
}

div.grid-list {
    background: none !important;
    display: flex;
    padding: 8px;
    overflow: auto;
    overflow-y: hidden;
}
.list-drawing-saving{
    position: absolute !important ;
    z-index: 1500 !important;
    left: 0;
    right: 0;
    margin-top: max(450px, 70vh);
    /* overflow: hidden !important; */
}
@media only screen and (max-height: 768px) and (orientation: landscape) {
    .list-drawing-saving{
        position: absolute !important ;
        z-index: 1500 !important;
        left: 0;
        right: 0;
        margin-top:  max(450px, 70vh);
        /* overflow: hidden !important; */
    }
}
.list-drawing-saving .btn-next-drawing
{
    position: fixed !important;
    right: 0;
    top: calc(95.57vh - 118.45px) !important;
    z-index: 299;
}
.list-drawing-saving .btn-prev-drawing
{
    position: fixed !important;
    left: 0;
    top: calc(95.57vh - 118.45px) !important;
    z-index: 299;
}
.list-drawing-saving .sub-block  {
    border-radius: 16px !important;
    padding: 0px !important;
    background-color: #C7C7C7 ;
    border: none;
}
.list-drawing-saving .sub-block .sub-block-title {
    color: white;
    height: 40px;
}
.list-drawing-saving .sub-img-block  {
    border-radius: 0px 0px 16px 16px !important;
    padding: 0px !important;
    height: 200px;
    text-align: center;
    min-width: 200px;
    max-width: 200px;
}

/* FOR SP */

.MuiGrid-root.MuiGrid-root#primary-map-sp {
    width: 100%;
    height: calc(var(--view-height, 1vh) * 100 - 210px);
    min-height: 100px;
    background-color: #efefef;
}

.MuiGrid-root.MuiGrid-root#primary-map-sp #primary-image-sp {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    overflow: hidden;
    display: inline-block;
}

div.grid-list-sp {
    z-index: 0;
    background: none !important;
    display: flex;
    padding: 8px;
    overflow: auto;
    overflow-y: hidden;
}

.scrollbarWidthNone {
    scrollbar-width: none;
}

.draggable-primary-sp {
    position: relative;
    height: calc(var(--view-height, 1vh) * 100 - 210px);
    min-height: 100px;
    background-color: #efefef;
    border: solid 1px #c7c7c7;
}

button.button-add-drawing-sp {
    left: calc(25% + 22px);
    transform: translateX(-50%);
}
.button-logwalk-menu-center-sp {
    left: calc(32% + 20px) !important;
}

button.button-edit-drawing-sp {
    right: calc(50%);
    transform: translateX(50%);
    line-height: 0.75rem;
}

.sub-block {
    margin: 0 10px !important;
    padding: 2px;
    border: 1px solid #000;
    border-radius: 4px;
}

.sub-block-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-block-title-sp {
    padding-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-block-title>span,
.sub-block-title-sp>span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    display: inline-block;
}

.sub-block-title>button {
    width: 34px !important;
    height: 15px !important;
    padding-bottom: 14px !important;
}

.sub-block-title-sp>button {
    height: 12px !important;
    padding-bottom: 11px !important;
    padding-left: 15px !important;
    width: 0 !important;
    min-width: 0 !important;
}

.image-title {
    text-align: left !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 130px);
}

.onsite-name {
    text-align: left !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.max-width-325 {
    max-width: calc(100% - 325px);
}

.max-width-435 {
    max-width: calc(100% - 435px);
}

.max-width-170 {
    max-width: calc(100% - 170px);
}

.max-width-40 {
    max-width: calc(100% - 40px);
}

.sub-img-block {
    display: flex;
    padding: 0 2px !important;
    background: none !important;
    border: 1px solid #c7c7c7;
    background-color: #fff !important;
    position: relative;
    height: 170px;
    text-align: center;
    min-width: 170px;
    max-width: 170px;
    width: 100%;
}

.sub-img-block-sp {
    padding: 0 2px !important;
    background: none !important;
    border: 1px solid #c7c7c7;
    background-color: #fff !important;
    position: relative;
    height: 170px;
    min-width: 170px;
    max-width: 170px;
    text-align: center;
}

.sub-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.vertical-primary-block img {
    width: auto !important;
  height: 100% !important;
    /* width: auto;
    height: 100%; */
}
.vertical-primary img {
    width: auto !important;
  height: 100% !important;
    /* width: auto;
    height: 100%; */
}
.vertical-primary-drawing-block img {
    width: auto;
    height: 100%;
}
.vertical-primary-drawing img {
    width: auto;
    height: 100%;
}

.vertical-sub-block img {
    width: auto !important;
  height: 100% !important;
    /* width: auto;
    height: 100%; */
}
.vertical-sub-compass-block img {
    width: auto;
    height: 100%;
}
.vertical-sub-compass-block img {
    width: auto;
    height: 100%;
}

.vertical-sub-block-dialog img {
    width: auto !important;
    height: 100% !important;
}

.pdf-btn-block {
    width: 280px !important;
    text-align: center;
}

.pdf-btn-block div:first-child {
    height: 45px;
}

.pdf-preview-content {
    height: 80vh;
    overflow-x: hidden;
    padding-top: 20px !important;
}

.page-selected-pc {
    height: calc(80vh - 70px);
    text-align: right;
}

.page-selected-sp {
    height: 400px;
    text-align: right;
}

.page-selected-pc>div:nth-child(2),
.page-selected-sp>div:nth-child(2) {
    position: relative;
    height: calc(100% - 50px) !important;
}

.page-unselected-pc,
.page-unselected-pc div.MuiGrid-container {
    text-align: center;
}

.page-unselected-sp,
.page-unselected-sp div.MuiGrid-container {
    /* height: 450px; */
    text-align: center;
}

.dialog-pdf-upload {
    width: calc(100% - 45px) !important;
}

.pdf-preview-content img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
}

.page-selected-pc>div:nth-child(2),
.page-selected-sp>div:nth-child(2) {
    border: 4px solid #c7c7c7;
    border-radius: 5px;
}

.page-selected-pc div,
.page-selected-sp div {
    width: auto;
    height: auto;
}

.adjust-image-block img,
.sub-image-block img {
    height: 100% !important;
}

.primary-image-block.vertical-primary-block,
.primary-image-block.vertical-primary-block div {
    height: 100%;
}

.page-selected-pc .vertical-primary-block>div>div,
.page-selected-sp .vertical-primary-block>div>div {
    width: auto;
    height: 100%;
}

div.image-button-block {
    height: auto !important;
    border: none;
    text-align: center;
}

.button-rotate {
    margin-bottom: 10px !important;
    background-color: #fff !important;
    box-shadow: none !important;
}

.sub-image-area {
    max-width: 29vw;
    width: 25vh;
    display: flex;
    padding: 5px 5px 5px 0;
}

.sub-image-area .sub-image-index {
    display: flex;
    width: 45px;
    justify-content: center;
    align-items: center;
}

.sub-image-block {
    max-width: 100%;
    max-height: 150px;
    width: 100%;
    height: 13vh;
    position: relative;
    background-color: #fff;
    border: 2px solid #c7c7c7;
    cursor: pointer;
}

.adjust-image-block {
    max-width: 100%;
    max-height: 150px;
    width: 100%;
    height: 13vh;
    position: relative;
    cursor: pointer;
    margin: 3px 3px 10px;
}

.adjust-image-block:last-child {
    margin-bottom: 3px;
}

.MuiFab-root.btn-next,
.MuiFab-root.btn-next:hover,
.MuiFab-root.btn-prev,
.MuiFab-root.btn-prev:hover {
    background-color: transparent;
    box-shadow: none;
}

.arrow-block {
    height: 50px;
    transform: translateX(15px);
}

.selected {
    background-color: rgba(22, 47, 80, 1) !important;
    color: #fff;
}

.adjust-image-selected {
    background-color: #162f50bf !important;
    border: 4px solid #162f50bf !important;
    border-radius: 4px;
}

.selected:hover {
    cursor: pointer;
    /* -webkit-box-shadow: 0px 0px 7px 2px #ee7676;
  box-shadow: 0px 0px 7px 2px #ee7676; */
}

.MuiButtonBase-root.selected-photos {
    position: absolute;
    top: 0;
    right: 0;
    color: #c7c7c7;
    z-index: 999;
}

.pdf-btn-block button {
    max-width: 100px;
}

.pin-spot {
    pointer-events: initial !important;
    /* cursor: crosshair; */
    cursor: url(../img/addPointCursor.svg) 8 8, auto;
}

.drawing-title {
    display: flex;
    white-space: nowrap;
    align-items: center;
    width: calc(50% - 80px);
    text-align: center;
}

button.drawing-button {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    height: inherit;
    color: rgba(22, 47, 80, 1);
}

button.drawing-button:hover,
button.drawing-button:focus {
    background-color: transparent;
    box-shadow: none;
}

.ReactCrop__rule-of-thirds-vt,
.ReactCrop__rule-of-thirds-hz {
    height: 0 !important;
    width: 0 !important;
}

.ReactCrop__rule-of-thirds-vt::before,
.ReactCrop__rule-of-thirds-vt::after,
.ReactCrop__rule-of-thirds-hz::before,
.ReactCrop__rule-of-thirds-hz::after {
    background-color: inherit !important;
}

.ReactCrop .ord-nw,
.ReactCrop .ord-n,
.ReactCrop .ord-ne,
.ReactCrop .ord-e,
.ReactCrop .ord-se,
.ReactCrop .ord-s,
.ReactCrop .ord-sw,
.ReactCrop .ord-w {
    height: 10px;
    width: 10px;
}

.width-inherit {
    width: inherit !important;
}

@media screen and (max-width: 768px) {
    .drawing-title {
        width: calc(90% - 80px);
    }
    .MuiButtonBase-root.selected-photos {
        top: 5px;
        right: 5px;
        transform: scale(1.8);
    }
    .sub-image-name {
        width: 90px !important;
    }
}

@media screen and (min-width: 1601px) {
    /* .page-selected-sp {
    height: calc(80vh - 140px);
    text-align: right;
  } */
    /* .sub-image-area {
    width: 310px;
  } */
    /* .sub-image-block {
    width: 280px;
    height: 150px;
  } */
    /* 
  .sub-image-name {
    width: calc(100% - 50px) !important;
    height: 30px !important;
    line-height: 30px;
    top: 7px !important;
  }

  .MuiButtonBase-root.selected-photos {
    top: 10px !important;
    right: 10px !important;
    transform: scale(2);
  } */
    /* .item-checkbox .selected-photos {
    top: auto !important;
    right: auto !important;
    transform: scale(1) !important;
  } */
}

@media screen and (min-width: 960px) {
    .pdf-btn-block {
        margin: 0 auto;
    }
    .sub-image-area {
        max-width: 310px;
        width: 30vh;
    }
    .sub-image-block {
        max-width: 275px;
        max-height: 150px;
        width: calc(30vh - 45px);
        height: 15vh;
        position: relative;
        background-color: #fff;
        border: 2px solid #c7c7c7;
        cursor: pointer;
    }
    .adjust-image-block {
        max-width: 275px;
        max-height: 150px;
        width: calc(30vh - 45px);
        height: 15vh;
        position: relative;
        cursor: pointer;
    }
}

@media screen and (max-width: 360px) {
    .pdf-btn-block div:first-child {
        display: flex;
        flex-wrap: nowrap;
    }
}

@media screen and (max-width: 600px) {
    .page-selected-pc,
    .page-selected-sp {
        height: 33vh;
    }
    .page-unselected-pc,
    .page-unselected-sp,
    .page-unselected-pc div.MuiGrid-container,
    .page-unselected-sp div.MuiGrid-container {
        height: auto;
        margin-top: 15px !important;
    }
    .sub-image-area {
        max-width: 100%;
        width: 25vh;
    }
    .adjust-image-block,
    .sub-image-block {
        max-width: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .page-unselected-pc,
    .page-unselected-sp,
    .page-unselected-pc div.MuiGrid-container,
    .page-unselected-sp div.MuiGrid-container {
        height: auto;
        margin-top: 45px !important;
    }
    .drawing-name-primary div:first-child {
        width: calc(100% - 25px) !important;
    }
    .button-rotate {
        width: 30px !important;
        height: 15px !important;
    }
    .button-rotate .MuiTouchRipple-root {
        height: 25px;
        width: 25px;
        transform: translate(10%, 25%);
    }
    .button-rotate .material-icons {
        font-size: 15px;
    }
}

.current-drawing {
    background-color: rgba(22, 47, 80, 1) !important;
    color: #fff;
}

.current-drawing button {
    color: #fff !important;
}

button.add-point-enable {
    background-color: rgba(22, 47, 80, 1) !important;
    color: #fff;
}

button.btn-copy-spot-enable {
    background-color: #fff;
    color: #162f50;
    border: 1px solid #162f50
}

button.btn-copy-spot-disable {
    background-color: #cecece !important;
    color: #162f50;
    border: 1px solid #162f50
}

.pin-spot-help {
    background-color: rgb(63, 63, 63, 0.9);
    border-radius: 0.8rem;
    max-width: 250px;
}

.pin-spot-help-description {
    background-color: #fff;
    padding: 5px 20px;
}

.pin-spot-help img {
    max-width: 160px;
}

.pin-spot-help-action {
    text-align: center;
    color: #fff;
}

.pin-spot-help-action svg {
    color: #fff;
}

.disable-select {
-webkit-touch-callout: none !important;
-webkit-user-select: none;
user-select: none !important;
/* -khtml-user-select: none;
-moz-user-select: none;
-o-user-select: none; */
user-select: none;
-webkit-user-drag: none
}
/* body .map-dialog-content
{
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
} */


/* .MuiDialogContent-root.image-content-dialog div.react-transform-component,
.MuiDialogContent-root.image-content-dialog div.react-transform-element {
  height: 100% !important;
  width: auto !important;
} */

@media only screen and (max-device-width: 960px) {
    .image-title {
        max-width: calc(100% - 40px);
    }
    /* .onsite-name {
    max-width: calc(100% - 40px) ;
  } */
}

@media only screen and (max-device-width: 960px) and (orientation: landscape) {
    .draggable-primary-sp {
        height: calc(var(--view-height, 1vh) * 100 - 90px);
        /* height: calc(var(--view-height, 1vh) * 130 - 90px); */
    }
    .MuiGrid-root.MuiGrid-root#primary-map-sp {
        height: calc(var(--view-height, 1vh) * 100 - 90px);
        /* height: calc(var(--view-height, 1vh) * 130 - 90px); */
    }
    .draggable-primary-sp-compasMode
    {
        height: calc(var(--view-height, 1vh) * 130 - 90px) !important;
    }
    .primary-map-sp-compasMode {
        /* height: calc(var(--view-height, 1vh) * 100 - 90px); */
        height: calc(var(--view-height, 1vh) * 130 - 90px);
    }
}

.drawing-dialog-content {
    width: calc(100% - 24px) !important;
    /* max-height: calc(100% - 100px) !important;
  height: 80vh; */
    margin-left: 0 !important;
    margin-top: 0 !important;
}

.drawing-dialog-height {
    width: calc(100% - 24px) !important;
    max-width: calc(100% - 24px) !important;
    /* max-height: calc(100% - 100px) !important; */
    margin-left: 0 !important;
    margin-top: 0 !important;
}

@media screen and (orientation: portrait) {
    .drawing-dialog-height-update {
        width: calc(100% - 24px) !important;
        max-width: calc(100% - 24px) !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
    }
    .drawing-dialog-height-update-sp {
        width: calc(100% - 24px) !important;
        max-width: calc(100% - 24px) !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
    }
}

@media screen and (orientation: landscape) {
    .drawing-dialog-height-update {
        width: calc(100% - 24px) !important;
        max-width: 65% !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
    }
    .drawing-dialog-height-update-sp {
        width: calc(100% - 24px) !important;
        max-width: calc(115vh + 33px) !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
        padding: 0px 12px !important;
    }
}

@media screen and (max-width: 568px) and (orientation: landscape) {
    .drawing-dialog-height-update-sp {
        width: calc(100% - 24px) !important;
        max-width: calc(115vh + 40px) !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
        padding: 0px 12px !important;
    }
}

.btn-prev-drawing {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 299;
}

.btn-next-drawing {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 299;
}

.btn-prev-drawing:focus,
.btn-next-drawing:focus {
    background-color: #e0e0e0 !important;
}

.complete-site {
    width: fit-content !important;
    color: #ff0000;
}

.incomplete-site-btn {
    background-color: #fff !important;
    color: #00346c !important;
    border-radius: 4px !important;
    border: 2px solid #00346c !important;
    margin-left: 10px !important;
    font-weight: 500 !important;
}

.selected-primary svg {
    margin-bottom: 0.5rem;
    transform: scale(1.8);
}

.drawing-name-primary {
    width: calc(100% - 80px) !important;
    max-width: 300px;
    height: 25px !important;
    background-color: rgba(22, 47, 80, 1);
    color: #fff;
    text-align: left;
    padding-left: 2px;
}

.drawing-name-primary div:first-child {
    width: calc(100% - 50px) !important;
    white-space: nowrap !important;
    overflow: hidden !important;
}

.drawing-name-primary div:first-child span:last-child {
    width: inherit;
}

.primary-index {
    text-align: left;
    background-color: #fff;
    color: rgba(22, 47, 80, 1);
    min-width: 30px;
    height: 23px;
    display: inline-block;
    text-align: center;
}

.btn-edit-name {
    height: 23px !important;
    cursor: pointer;
}

.edit-button svg {
    width: 0.7em;
}

.sub-image-name {
    position: absolute;
    top: 2px;
    left: 5px;
    width: calc(100% - 30px);
    height: 20px;
    color: white;
    background-color: rgba(22, 47, 80, 1);
    white-space: nowrap;
    overflow: hidden;
    padding: 0 3px;
    opacity: 0.9;
}

.button-disable {
    background-color: rgb(194, 194, 194) !important;
    color: rgba(22, 47, 80, 1) !important;
}

@media screen and (max-width: 960px) {
    .sub-image-name {
        width: calc(100% - 40px) !important;
    }
}

.input-drawing-name {
    width: calc(100% - 105px) !important;
    height: 20px !important;
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
    color: #fff;
}

.sub-block-title .edit-button {
    cursor: pointer !important;
}

.mapDialog-content {
    background-color: #fffffff2 !important;
}

.button-change-site {
    right: 45px;
    position: sticky !important;
}

.fixed-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1099;
    width: 100vw;
    height: 100vh;
    background-color: #666666b3;
}
.fixed-box-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80px;
    z-index: 1103;
    background-color: #666666b3;
}
.fixed-box-header-sp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 64px;
    z-index: 1103;
    background-color: #666666b3;
}
.copy-drawing-container,
.copy-drawing-container-sp{
    position: fixed;
    top: 0;
    left: 0;
    width: 99vw;
    height: 100vh;
    z-index: 1300;
    background-color: #666666b3;
    overflow: auto;
}

.copy-drawing-container .copy-drawing-container-child{
    height: 100vh;
    min-height: 420px;
    overflow: auto;
}

.copy-drawing-container-sp .copy-drawing-container-child{
    height: 100vh;
    min-height: 340px;
    overflow: auto;
}

.copy-drawing-container .copy-drawing-wrapper{
    position: absolute;
    width: 300px;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #fff;
    border: 2px solid #162f50;
    color: #162f50;
    /* min-height: 255px; */

}

.feature-flag-detail-dialog{
    top: 20vh !important;
}

.copy-drawing-container-sp .copy-drawing-wrapper{
    position: absolute;
    width: 270px;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    background-color: #fff;
    border: 2px solid #162f50;
    color: #162f50;
}

.relative-box {
    position: relative;
    z-index: 1099;
    background-color: #fff;
}
.z-index-1101{
    z-index: 1101;
}
.relative-box-sp{
    position: relative;
  text-align: center;
}
.relative-box-compass-sp{
    position: relative;
    /* z-index: 1101; */
    background-color: #fff;
}


.compass-view-icon {
    width: 15% !important;
    height: 15% !important;
    position: absolute;
}

.compass-view-icon-setting-currentDrawing {
    width: 60% !important;
    height: 60% !important;
    margin-top: 15%;
}

.compass-view-icon-setting-selected {
    width: 40% !important;
    height: 40% !important;
    margin-top: 30%;
}

.compass-view-icon-setting {
    width: 15% !important;
    height: 15% !important;
}

/* ------------------------------ */


.compassview-container {
    width: 100% !important;
    height: 100%;
    /* text-align: right; */
    /* z-index: 10; */
    z-index: 100;
    position: absolute;
}

.compassview-container-saving {
    width: 100% !important;
    height: 100%;
    text-align: center;
    z-index: 10;
}

.compass-setting-icon {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.compass-setting-container {
    position: absolute;
    z-index: 1 !important;
    padding: 3px !important;
}

.compass-setting-rotation-container {
    position: absolute;
    border-radius: 50%;
}

.rotation-container-selected {
    background-color: rgba(255, 255, 255, 0.5);
    border: dotted #1d59f2 1px;
}

.compass-setting-icon-container {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content: center;
    z-index: 1;
}

.compass-setting-rotation-icon-container {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: -10px !important;
}

.compass-setting-rotation-icon-container p {
    margin-top: -5px;
}

.compass-setting-control-container {
    position: absolute;
    width: 30%;
    text-align: center;
    margin-left: 34%;
}

.margin-top-160px {
    /* margin-top: 170px; */
    margin-top: 80%;
}

.margin-top-40px {
    margin-top: 16%;
    /* margin-top: 30px; */
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.pd-0px {
    padding: 0px !important;
}

.compass-setting-button-icon {
    color: #1d59f2;
}

.compass-setting-button-icon-disable {
    color: #c7c7c7;
}

.compass-setting-direction-label {
    /* background-color: rgb(245, 255, 178); */
    color: #00346c;
    width: 40%;
    margin-left: 30%;
    /* border: solid 1px; */
    /* line-height: 1.2; */
    font: normal normal normal 15px Roboto;
}

.compass-setting-direction-label sup {
    top: 0em;
    font-size: 80%;
}

.compass-setting-save-button {
    position: absolute;
    width: 100%;
    /* margin-top: 210px; */
    text-align: center;
    bottom: 0px;
    z-index: 90;
}

/* minimum size */

.compass-setting-container-minimumsize {
    height: 200px !important;
    width: 160px !important;
    max-width: 160px !important;
}

.compass-setting-rotation-container-minimumsize {
    height: 154px !important;
    width: 154px !important;
    max-width: 154px !important;
}

.compass-setting-icon-minimumsize {
    width: 70px !important;
    height: 70px !important;
}

/* Mini size */

.compass-setting-container-minisize {
    height: 250px !important;
    width: 210px !important;
    max-width: 210px !important;
}

.compass-setting-rotation-container-minisize {
    height: 204px !important;
    width: 204px !important;
    max-width: 204px !important;
}

.compass-setting-icon-minisize {
    width: 100px !important;
    height: 100px !important;
}



/* normal size  */

.compass-setting-container-normalsize {
    height: 309px !important;
    width: 266px !important;
    max-width: 266px !important;
}

.compass-setting-rotation-container-normalsize {
    height: 260px !important;
    width: 260px !important;
    max-width: 260px !important;
}

.compass-setting-icon-normalsize {
    width: 150px !important;
    height: 150px !important;
}

.compass-setting-icon-complete {
    width: 100px !important;
    height: 100px !important;
}
.compass-sp-setting-icon-complete {
    width: 100px !important;
    height: 100px !important;
}
.compass-sp-setting-icon-complete-landscape {
    width: 100px !important;
    height: 100px !important;
}

.compass-setting-container-complete {
    height: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
}
.compass-sp-setting-container-complete {
    height: 100px !important;
    width: 100px !important;
    max-width: 100px !important;
}
.compass-sp-setting-container-complete-landscape {
    height: 100px !important;
    width: 100px !important;
    max-width: 100px !important;
}

.compass-setting-rotation-container-complete {
    height: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
}
.compass-sp-setting-rotation-container-complete {
    height: 100px !important;
    width: 100px !important;
    max-width: 100px !important;
}
.compass-sp-setting-rotation-container-complete-landscape {
    height: 100px !important;
    width: 100px !important;
    max-width: 100px !important;
}

/* Maxsize */

.compass-setting-container-maxsize {
    height: 359px !important;
    width: 316px !important;
    max-width: 316px !important;
}

.compass-setting-rotation-container-maxsize {
    height: 308px !important;
    width: 308px !important;
    max-width: 308px !important;
}

.compass-setting-icon-maxsize {
    width: 200px !important;
    height: 200px !important;
}


/* sp */

.compass-sp-setting-container-minimumsize {
    height: 210px !important;
    width: 170px !important;
    max-width: 170px !important;
}

.compass-sp-setting-rotation-container-minimumsize {
    height: 164px !important;
    width: 164px !important;
    max-width: 164px !important;
}

.compass-sp-setting-icon-minimumsize {
    width: 70px !important;
    height: 70px !important;
}

.compass-sp-setting-container-minisize {
    height: 230px !important;
    width: 190px !important;
    max-width: 190px !important;
}

.compass-sp-setting-rotation-container-minisize {
    height: 184px !important;
    width: 184px !important;
    max-width: 184px !important;
}

.compass-sp-setting-icon-minisize {
    width: 80px !important;
    height: 80px !important;
}


.compass-sp-setting-container-normalsize {
    height: 260px !important;
    width: 220px !important;
    max-width: 220px !important;
}

.compass-sp-setting-rotation-container-normalsize {
    height: 214px !important;
    width: 214px !important;
    max-width: 214px !important;
}

.compass-sp-setting-icon-normalsize {
    width: 120px !important;
    height: 120px !important;
}


.compass-sp-setting-container-maxsize {
    height: 300px !important;
    width: 260px !important;
    max-width: 260px !important;
}

.compass-sp-setting-rotation-container-maxsize {
    height: 254px !important;
    width: 254px !important;
    max-width: 254px !important;
}

.compass-sp-setting-icon-maxsize {
    width: 160px !important;
    height: 160px !important;
}


/*------------- SP Landscape-------------- */
.compass-sp-setting-container-minimumsize-landscape {
    height: 52vh !important;
    width: 36vh !important;
    max-width: 36vh !important;
}

.compass-sp-setting-rotation-container-minimumsize-landscape {
    height: calc(36vh - 6px) !important;
    width: calc(36vh - 6px) !important;
    max-width: calc(36vh - 6px) !important;
    /* padding: 0px 3px 0px 3px !important; */
}

.compass-sp-setting-icon-minimumsize-landscape {
    width: 17vh !important;
    height: 17vh !important;
}

.compass-sp-setting-container-minisize-landscape {
    height: 57vh !important;
    width: 41vh !important;
    max-width: 41vh !important;
}

.compass-sp-setting-rotation-container-minisize-landscape {
    height: calc(41vh - 6px) !important;
    width: calc(41vh - 6px) !important;
    max-width: calc(41vh - 6px) !important;
    /* padding: 0px 3px 0px 3px !important; */
}

.compass-sp-setting-icon-minisize-landscape {
    width: 20vh !important;
    height: 20vh !important;
}


.compass-sp-setting-container-normalsize-landscape {
    height: 60vh !important;
    width: 44vh !important;
    max-width: 44vh !important;
}

.compass-sp-setting-rotation-container-normalsize-landscape {
    height: calc(44vh - 6px) !important;
    width: calc(44vh - 6px) !important;
    max-width: calc(44vh - 6px) !important;

}

.compass-sp-setting-icon-normalsize-landscape {
    width: 22vh !important;
    height: 22vh !important;
}


.compass-sp-setting-container-maxsize-landscape {
    height: 65vh !important;
    width: 49vh !important;
    max-width: 49vh !important;
}

.compass-sp-setting-rotation-container-maxsize-landscape {
    height: calc(49vh - 6px) !important;
    width: calc(49vh - 6px) !important;
    max-width: calc(49vh - 6px) !important;
}

.compass-sp-setting-icon-maxsize-landscape {
    width: 25vh !important;
    height: 25vh !important;
}
/*END------------- SP Landscape-------------- END */
.compass-setting-viewer-container-normalsize {
    height: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
}

.compass-setting-viewer-rotation-container-normalsize {
    position: absolute;
    border-radius: 50%;
    height: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
}

.compass-setting-viewer-icon-normalsize {
    width: 100px !important;
    height: 100px !important;
}

.compass-setting-confirm-dialog-container {
    border-radius: 20px !important;
    background-color: rgba(22, 47, 80, 1) !important;
}

.compass-setting-dialog-container {
    border-radius: 20px !important;
    background-color: rgba(22, 47, 80, 1) !important;
}

.compass-setting-confirm-dialog-container-save {
    border-radius: 20px !important;
    width: 24vw !important;
    /* height: 50vh; */
    background-color: rgba(22, 47, 80, 1) !important;
    z-index: 1103 !important;
    /* position: fixed; */
    position: absolute;
    /* bottom: calc(4.43vh + 300px); */
    top: max(13vh, 90px);
    left: calc(calc(100vw - 466px)/2);
    min-width: 466px !important;
    max-width: 466px !important;
    height: 354px !important;
}
.compass-setting-confirm-dialog-container-save .MuiDialogTitle-root
{
    padding: 40px 24px 20px 24px !important
}
.compass-saving-container {
    top: 0vh;
    left: 0;
    right: 0;
    position: fixed;
    height: 100vh;
    z-index: 1500 !important;
    overflow-y: scroll;
}
.compass-saving-container-div
{
    height: 860px;
    z-index: 1400 !important;
}

.delete-link-LW-confirm-dialog-container {
    border-radius: 20px !important;
    background-color: #E6EDF6 !important;
}

@media only screen and (orientation: portrait) {
    
.compass-sp-view-icon {
    width: 15% !important;
    height: 15% !important;
}

.compass-sp-view-icon-setting-currentDrawing {
    width: 40% !important;
    height: 40% !important;
    margin-top: 25%;
}

.compass-sp-view-icon-setting-selected {
    width: 30% !important;
    height: 30% !important;
    margin-top: 35%;
}

.compass-sp-view-icon-setting {
    width: 15% !important;
    height: 15% !important;
}
    .compass-setting-confirm-dialog-container-save-sp {
        border-radius: 20px !important;
        /* height: 50vh; */
        background-color: rgba(22, 47, 80, 1) !important;
        z-index: 1102;
        position: fixed;
        bottom: -20px;
        left: 0;
        right: 0;
        /* height: 35vh; */
         min-height: 220px; 
        /* max-height: 250px; */
    }
    .current-drawing-compass-sp {
        background-color: rgba(22, 47, 80, 1);
        color: #fff;
        position: fixed;
        bottom: 38vh   ;
        left: 13vw;
        right: 13vw;
        z-index: 1300;
    }
    .selected-drawing-container{
        position: absolute !important;
        top: -12% !important;
        left: 3%;
        display: inline;
    }
    .selected-drawing-checkbox-wrapper{
        position: absolute;
        top: 0;
        left: -10%;
        width: 22px;
        height: 24px;
        background-color: #00356D;
        display: inline;
        margin-top: 2px;
    }
    .sub-img-block-compass-sp {
        padding: 0 0px !important;
        background: none !important;
        /* border: 1px solid #c7c7c7; */
        background-color: #fff !important;
        position: relative;
        /* height: 170px;
        min-width: 170px;
        max-width: 170px; */
        height: 37vh;
        min-width: 30vw;
        /* max-width: 80vw; */
        width: 100%;
        text-align: center;
        border-radius: 0 0 16px 16px;
        overflow: hidden;
    }
}
.compass-setting-confirm-dialog-container-save-sp-landscape {
    border-radius: 20px 20px 0 0 !important;
    /* height: 50vh; */
    background-color: rgba(22, 47, 80, 1) !important;
    z-index: 1102;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    /* height: 30%; */
    height: 32vh;
    /* min-height: 128px; */
    max-height: 150px;
}
@media only screen and (orientation: landscape) {
       
.compass-sp-view-icon {
    width: 15% !important;
    height: 15% !important;
}

.compass-sp-view-icon-setting-currentDrawing {
    width: 60% !important;
    height: 60% !important;
    margin-top: 10%;
}

.compass-sp-view-icon-setting-selected {
    width: 40% !important;
    height: 40% !important;
    margin-top: 10%;
}

.compass-sp-view-icon-setting {
    width: 20% !important;
    height: 20% !important;
}
    .selected-drawing-checkbox-wrapper{
        position: absolute;
        top: 0;
        left: -15%;
        width: 22px;
        height: 23px;
        background-color: #00356D;
        display: inline;
        margin-top: 2px;
    }
    .sub-img-block-compass-sp {
        padding: 0 0px !important;
        background: none !important;
        /* border: 1px solid #c7c7c7; */
        background-color: #fff !important;
        position: relative;
        /* height: 170px;
        min-width: 170px;
        max-width: 170px; */
        height: 34vh;
        min-width: 30vw;
        /* max-width: 70vw; */
        width: 100%;
        text-align: center;
        border-radius: 0 0 16px 16px;
        overflow: hidden;
    }
    
    .current-drawing-compass-sp {
        background-color: rgba(22, 47, 80, 1);
        color: #fff;
        position: fixed;
        bottom: 35%;
        left: 26.56vw;
        right: 26.56vw;
        z-index: 1300;
    }
    .selected-drawing-container{
        position: absolute !important;
        top: -10vh !important;
        left: 3% !important;
        display: inline;
    }
}
.compass-dialog-sp-title{
    padding: 8px 24px !important;
}
.compass-dialog-sp-title-landscape{
    padding: 14px 0px 0px 0px !important;
    width: 7%;
}
.compass-dialog-sp-title-center-landscape
{
    /* padding: 14px 0px 0px 0px !important; */
    width: 7%;
}
.compass-dialog-title-sp{
    font-size: 17px !important;
    letter-spacing: 0px !important;

}
.compass-dialog-text-sp{
    font-size: 12px !important;
}
.compass-setting-confirm-dialog-container-save .confirm-content
{
    padding: 0px 24px 8px 24px !important;
}
.close-button-compass {
    position: absolute;
    left: 10px;
    top: 25px;
}
.close-button-compass-sp {
    position: absolute;
    left: 0px;
    top: -10px;
}
.close-button-compass-sp-landscape {
    position: absolute;
    left: -1vw;
    top: 0px;
}
.compass-setting-confirm-div-left{
    width: 48%;
    position: relative;
}
.compass-setting-confirm-div-right{
    width: 45%;
    position: relative;
}

.compass-setting-confirm-dialog-btn-option {
    background-color: #e6edf5 !important;
    color: #333333 !important;
    transition: 0.3s !important;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-transform: none !important;
    font-size: 20px !important;
    font-weight: bold !important;
    /* font-family: "Roboto" !important; */
}
.compass-setting-confirm-dialog-btn-option-sp {
    background-color: #e6edf5 !important;
    color: #333333 !important;
    transition: 0.3s !important;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: bold !important;
}
.compass-setting-confirm-dialog-sp {
    background-color: #e6edf5 !important;
    color: #333333 !important;
    transition: 0.3s !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: bold !important;
    margin: 10px 10% 10px 10%!important;
    width: 80% !important;
}

.compass-setting-confirm-save-dialog-btn-option-1 {
    /* background-color: #E6EDF5 !important; */
    color: #ffffff !important;
    transition: 0.3s !important;
    margin-bottom: 10px !important;
    margin-top: 0px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-transform: none !important;
    width: 60% !important;
    margin-left: 20% !important;
    border: 1px solid #f8f9f9 !important;
    border-radius: 5px;
}
.compass-setting-confirm-save-dialog-btn-option-sp1 {
    /* background-color: #E6EDF5 !important; */
    color: #ffffff !important;
    transition: 0.3s !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-transform: none !important;
    width: 60% !important;
    /* margin-left: 20% !important; */
    border: 1px solid #f8f9f9 !important;
    border-radius: 5px;
    line-height: 1 !important;
}
.compass-setting-confirm-save-dialog-btn-option-sp1-landscape {
    /* background-color: #E6EDF5 !important; */
    position: absolute !important;
    color: #ffffff !important;
    transition: 0.3s !important;
    bottom: 3vh;
    width: 40vw;
    max-width: 234px !important;
    right: 0;
    text-transform: none !important;
    /* width: 60% !important; */
    border: 1px solid #f8f9f9 !important;
    border-radius: 5px;
    font-size: 15px !important;
    line-height: 1.3 !important;    
}
.compass-setting-confirm-dialog-sp-landscape {
    background-color: #e6edf5 !important;
    color: #333333 !important;
    transition: 0.3s !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 1.3 !important; 
    bottom: 3vh;
    left: 0;
    width: 40vw;
    max-width: 234px !important;
    position: absolute !important;
}
.margin-top-50
{
    margin-top: 13vh !important;
}

.compass-setting-dialog-btn-option-1 {
    background-color: #e6edf5 !important;
    color: #333333 !important;
    transition: 0.3s !important;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-transform: none !important;
    width: 40% !important;
    margin: 3% !important;
    font: bold 16px "Roboto" !important;
}
.compass-setting-dialog-btn-option-2 {
    background-color: #e6edf5 !important;
    color: #333333 !important;
    transition: 0.3s !important;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-transform: none !important;
    width: 40% !important;
    margin: 3% !important;
    font:  16px "Roboto" !important;
}

.dialog-option-transitions {
    position: absolute !important;
    left: 0;
    right: 0;
    margin: 0 !important;
    bottom: 0;
    max-width: 100% !important;
    box-shadow: 0 10px 10px 10px #808080 !important;
}
.dialog-option-transitions ul{
    width: 100%;
    text-align: center; 
    padding-bottom: 30px;
}
.dialog-option-transitions li{
    font-size: 17px;
    font-weight: bold;
  letter-spacing: 0px;
   padding: 10px;
   color: #333333;
}
.dialog-option-transitions li:not(:last-child){
    border-bottom: solid 0.01px lightgray;
}
.dialog-option-transitions li:hover{
   
    background-color: lightgray;
}
.dialog-option-transitions hr{
  
   color: #333333;
}

.dialog-option-transitions-close-button{
    position: absolute !important;
    top: 15px;
    right: 15px;
    background-color: #fff;
    box-shadow: none;
    color: #333333;
}
.dialog-backdrop-transparent{
    background-color: rgba(0, 0, 0, 0.0) !important;
}
.option-btn-container-sp{
    margin-bottom: 20px;
}

.sub-block-compass-sp {
    margin: 0 10px !important;
    /* padding: 2px; */
    /* border: 1px solid #000; */
    border-radius: 16px !important;
}

.sub-block-compass-sp-title{
    height: 34px;
}
.compass-sp-back{
    position: absolute;
    top: 45%;
    right: -20%;
}
.compass-sp-next{
    position: absolute;
    top: 45%;
    left: -20%;
}
.compass-sp-arrow-left-disable{
    border-right: 15px solid grey !important;
}
.compass-sp-arrow-right-disable{
    border-left: 15px solid grey !important;
}
.compass-arrow-right {
    position: absolute;
    top: 40%;
    right: -15%;
    width: 0; 
    height: 0; 
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 15px solid #ffffff;
  }
  .compass-arrow-left {
    position: absolute;
    top: 40%;
    left: -15%;
    width: 0; 
    height: 0; 
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 15px solid #ffffff;
  }
  .compass-selected-drawing {
    transform: scale(1.8);
    margin-right: 10px !important;
    /* color: rgba(22, 47, 80, 1) !important;
     */
     color:#ffffff !important;
}
.compass-selected-drawing-white{
    color:#ffffff !important;
}
.compass-confirm-dialog-title{
    font-size: 20px !important;
}
.compass-confirm-dialog-title-container{
padding: 0 !important;
}
.confirm-content-sp{
    padding: 4px 4px !important;
}
.display-inline-block{
    display: inline-block;
}
.li-log-sp-disable{
    color: #808080 !important;
    opacity: 0.7;
}
.li-log-sp-disable:hover{
    background-color: #fff !important;
}
.width-100 {
    width: 100% !important;
}
.z-index-120{
    z-index: 120;
  }
/* #primary-image{
    
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-touch-callout:none;
    pointer-events:none;
    
} */

.LW-link-confirm-delete-btn-option {
    background-color: red !important;
    color: #fff !important;
    transition: 0.3s !important;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: bold !important;
}

.call-receive-modal {
    position: fixed;
    top: 0;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(51, 51, 51, 0.6);
    user-select: none;
}

.call-receive-container {
    background-color: white;
    width: 296px !important;
    border-radius: 8px;
    padding: 24px 32px;
}

.cancle-call-button {
    background-color: #B00020 !important;
    width: 48px !important;
    height: 48px !important;
}

.accept-call-button {
    background-color: #162F50 !important;
    width: 48px !important;
    height: 48px !important;
}

.calling-confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(51, 51, 51, 0.6);
    user-select: none;
}

.calling-confirm-container {
    background-color: white;
    width: 364px !important;
    border-radius: 8px;
    padding: 16px 32px;
    position: relative;

}

.site-logmeet-tab-container {
    margin-top: 20px;
    position: relative;
}

.site-logmeet-tab-refresh-button {
    position: absolute;
    right: 0;
    top: 18px;
}

.site-logmeet-tab-refresh-button-sp {
    position: absolute;
    right: 0;
    top: 0;
}

.history-call-row {
    height: 58px;
    background-color: #F8F9F9;
    border: solid 1px #C7C7C7;
    overflow: hidden;
    border-radius: 12px;
    margin-top: 16px !important;
}

.history-call-row-all-sp {
    height: 65px;
    background-color: #F8F9F9;
    border: solid 1px #C7C7C7;
    overflow: hidden;
    border-radius: 12px;
    margin-top: 16px !important;
}

.history-call-row:hover {
    background-color: #F0F0F0;
    transition: .3s;
}

.history-call-row-calling {
    border: solid 2px #8FC31F;
    animation: blink 1s;
    animation-iteration-count: infinite;
}

@keyframes blink {
    50% {
        border-color: #C7C7C7;
    }
}

.join-call-confirm-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(51, 51, 51, 0.6);
    user-select: none;
}

.join-call-confirm-container {
    position: relative;
    background-color: white;
    border-radius: 8px;
    width: 414px;
    height: 197px;
    padding: 24px;
}

.user-join-container {
    justify-content: space-around;
    margin-top: 12px;
}

.user-join {
    color: #333333;
    font-weight: normal;
    font-family: "M PLUS 1";
    font-size: 14px;
    border-radius: 24px;
    border: solid 1px #162F50;
    height: 29px;
    line-height: 29px;
    padding: 0 8px;
}

.call-image-item-container {
    background-color: #F8F9F9;
    border: solid 1px #C7C7C7;
    border-radius: 8px;
    margin-bottom: 32px !important;
    padding: 16px;
}

.site-logmeet-tab-detail-container textarea:focus {
    background-color: white !important;
    border: solid 1px #808080 !important;
    box-shadow: unset !important;
}

.site-logmeet-tab-detail-container input:focus {
    background-color: white !important;
    border: solid 1px #808080 !important;
    box-shadow: unset !important;
}

.site-logmeet-tab-detail-container textarea:focus-visible {
    background-color: white !important;
    border: solid 1px #808080 !important;
    outline: unset !important;
}

.site-logmeet-tab-detail-container input:focus-visible {
    background-color: white !important;
    border: solid 1px #808080 !important;
    outline: unset !important;
}

.user-history-calling {
    border: solid 2px #8FC31F;
    animation: blink 1s;
    animation-iteration-count: infinite;
}

.user-history-cancel {
    border: solid 2px #ECA340 !important;
}

.user-history-missing {
    border: solid 2px #B00020 !important;
}

.user-history-calling-mising {
    border: solid 2px #8FC31F;
    animation: blink 1s;
    animation-iteration-count: infinite;
}

.history-search-sites-container {
    position: absolute;
    z-index: 99999;
    width: 246px;
    top: 50px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 12px 15px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.history-search-sites-container input:focus {
    background-color: #ECEDED !important;
    border: unset !important;
    box-shadow: unset !important;
}

.history-search-sites-container input:focus-visible {
    background-color: #ECEDED !important;
    border: unset !important;
    outline: unset !important;
}

.btn-select-sites-history:hover {
    background-color: #808080 !important;
}

.history-search-sites-container ::-webkit-scrollbar {
    width: 7px;
}

.history-search-sites-container ::-webkit-scrollbar-thumb {
    background: #C7C7C7;
    border-radius: 10px;
}

.history-search-sites-container ::-webkit-scrollbar-thumb:hover {
    background: #808080;
}

.history-search-sites-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99998;
}

.logmeet-center-div {
    display: flex;
    justify-content: center;
    align-content: center;
}

.text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}