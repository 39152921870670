/* .profile-list {
  width: 240px;
  max-width: 240px;
  border-right: 1px solid rgba(240, 240, 240, 1);
  box-shadow: 10px 0px 10px -10px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 80px);
} */

.profile-list-wrap {
  max-width: 240px;
  width: 100%;
  height: calc(100vh - 80px);
  padding-bottom: 47px;
  border-right: 1px solid rgba(240, 240, 240, 1);
  box-shadow: 10px 0px 10px -10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.popover-item:last-child {
  color: rgba(242, 39, 53, 1);
}

.profile-content {
  padding: 35px;
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.profile-content:focus {
  outline: none;
  border: none;
}

.button.submit-button {
  margin-top: 20px;
  align-items: center;
}

.MuiGrid-root.submit-button {
  justify-content: center;
  margin-top: 35px;
  display: flex;
}

.confirm-password {
  margin-left: 15px;
}

.message-success {
  justify-content: center;
  margin-top: 20px;
}
