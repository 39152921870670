@charset "UTF-8";

/* ==========================================================
 login
========================================================== */
input {
  margin: 0;
  padding: 0;
}

.area-login-wrap {
  position: relative;
  min-height: 100vh;
  padding: 140px 7%;
  background: url("../img/bg_login_02.png") no-repeat;
  background-size: cover;
}

.area-login-wrap::after {
  content: "";
  background-color: #00000080;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
}

.area-logo {
  max-width: 135px;
  position: relative;
  z-index: 1;
  width: 100%;
  background: url("../img/logo_02.png") no-repeat;
  background-size: 100%;
}

.area-logo-sp {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 120px;
  background: url("../img/logo_02.png") no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: 60px;
}

.area-login {
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 175px 6%;
  background: rgba(255, 255, 255, 0.85);
}

.area-login-sp {
  position: relative;
  z-index: 1;
  padding: 45px 15px;
  background: rgba(255, 255, 255, 0.85);
  max-width: 450px;
  width: 100%;
}

.ttl-login {
  color: #333333;
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: "M PLUS 1p", sans-serif;
  text-align: left;
  letter-spacing: 0;
}

.txt-login-sub {
  opacity: 1;
  margin-bottom: 40px;
  color: #333333;
  font-size: 1.1rem;
  font-family: "M PLUS 1p", sans-serif;
  text-align: left;
  letter-spacing: 0;
}

.btn-login {
  opacity: 1;
  margin: 0 auto;
  border-radius: 3px;
  background: #00356d 0% 0% no-repeat padding-box;
  color: #ffffffde;
  width: 100%;
  display: block;
  margin-top: 25px;
  padding: 1rem;
  margin-bottom: 10px;
}

.btn-login:focus {
  background: #00356d 0% 0% no-repeat padding-box;
}

.ttl-login-input {
  display: block;
  opacity: 1;
  color: #00356d;
  font-family: "M PLUS 1p", sans-serif;
  letter-spacing: 0.02vw;
  text-align: left;
}

.sec-utility {
  display: flex;
}

.txt-login-memory {
  color: #808080;
  font-size: 0.85rem;
  letter-spacing: 0;
}

.box-forget-password {
  margin-left: auto;
}

.txt-forget-passowrd {
  color: #808080;
  font-size: 0.75rem;
  letter-spacing: 0;
}

.txt-link-login {
  color: #00356d;
  text-decoration: underline;
}

.input-login input:not(.browser-default) {
  border-bottom: none;
  margin-bottom: 0;
  padding: 27px 12px 10px;
  height: auto;
  font-size: 0.9rem;
  width: 450px;
}

.input-login-sp input:not(.browser-default) {
  width: 100%;
  max-width: 450px;
}

.input-login input[type="text"]:focus {
  background-color: transparent;
}

.input-login input:not(.browser-default):focus:not([readonly]) {
  box-shadow: none;
  border-bottom: none;
}

.input-login .MuiFilledInput-underline:before,
.input-login .MuiFilledInput-underline:hover:before {
  border-color: #2b57d0;
}

.wrap-form {
  padding: 0 85px;
  width: 450px;
  box-sizing: content-box;
}

.relative {
  position: relative;
}

button.show-password {
  position: absolute;
  right: 0;
  top: 30px;
}

.return-login {
  font-size: 0.8rem;
  color: #2b57d0;
  margin-top: 15px;
}

.return-login:hover,
.decorator-none:hover {
  text-decoration: none;
}

.btn-link {
  padding: 0;
}

.btn-link > a {
  padding: 1rem;
  display: block;
}

.signup {
  padding: 80px 6%;
  background: #fff;
}

.form-signup {
  position: absolute;
  top: 0;
  width: 100%;
}
