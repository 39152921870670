header.header-menu {
  background-color: rgba(22, 47, 80, 1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.header-menu-fix-spot
{
  background-color: #404040 !important;
}

header.header-menu.bg-red {
  background-color: red;
}

.menuButton {
  margin-right: 15px;
}

.title {
  flex-grow: 1;
}

.reset-link:hover {
  text-decoration: none;
}

.svg-inline--fa.width-icon {
  width: 1em;
}

.MuiTypography-root.text-bold {
  font-weight: 600;
}

button.margin-x {
  margin: 0 1rem;
}

ul.padding-y {
  padding: 1.5rem 0;
}

svg.icon {
  font-size: 2rem;
}

.MuiListItemIcon-root.icon-menu {
  min-width: 45px;
}

.header-left {
  background: #fff;
  padding: 15px 35px 15px 20px;
  min-width: 240px;
  flex-basis: 240px;
}

.header-left .header-menu-button {
  margin-right: 20px;
}

button.header-menu-button:focus {
  background-color: transparent;
}

.header-right {
  justify-content: space-between;
  width: 100%;
  padding: 20px 40px;
}

.header-menu .primary-font-family {
  font-family: "M PLUS 1p", sans-serif;
}

.draw-menu > .MuiBackdrop-root {
  background: transparent;
}

.draw-menu > .draw-paper {
  top: 80px;
  box-shadow: none;
  min-width: 240px;
  background-color: rgba(22, 47, 80, 1);
}

.MuiListItem-button.list-item:hover {
  background-color: rgba(128, 128, 128, 1);
}

.MuiButtonBase-root.list-item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-sp {
  padding: 15px;
}

.search-header > input:not(.browser-default) {
  margin: 0;
  border-bottom: none;
  background-color: #244065;
  padding: 0 50px 0 15px;
  color: #fff;
  font-size: 0.9rem;
  border-radius: 3px;
  height: 2rem;
  font-family: "M PLUS 1p", sans-serif;
}

.search-header > input:not(.browser-default)::place-holder {
  color: #808080;
}

.search-header > input:not(.browser-default):focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
  outline: none;
}

.image-contact {
  height: 60px;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: 20px;
}
