.search-box {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  background-color: #007cff;
}
.search-box > button {
  width: 60px;
  height: 45px;
  background-color: #007cff;
}

.search-box-input {
  display: flex;
  flex-grow: 1;
  height: 45px;
  overflow: hidden;
  background-color: #007cff;
}

.search-box-input > input {
  width: 100%;
  height: 45px;
  padding: 12px 6px 12px 40px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 20px;
  margin: 0px;
  border-radius: 4px;
  background-color: white;
}
.search-box-input > span {
  position: absolute;
  font-size: 30px;
  width: 24px;
  height: 24px;
  left: 4px;
  top: 2px;
}
.list-mention-box {
  overflow-y: auto;
  padding: 14px 0px 20px 10px;
  white-space: nowrap;
}
.dialog-mention-item {
  padding: 6px 11px 0px 0px;
  display: inline-block;
  position: relative;
}
.dialog-mention-item > div {
  padding: 8px 15px;
  font-size: 15px;
  line-height: 20px;
  border-radius: 18px;
  background-color: white;
  color: #00356d;
}
.dialog-mention-item > span {
  display: block;
  width: 20px;
  height: 20px;
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  background-color: #c7c7c7;
  cursor: pointer;
  line-height: 20px;
}
.mentions-box {
  border: 1px solid #bebebe;
  border-radius: 4px;
  display: flex;
  justify-content: start;
  flex-wrap: nowrap;
  min-height: 45px;
  max-height: 90px;
  overflow-x: auto;
  padding: 12px;
  cursor: pointer;
  background-color: rgb(248, 249, 249);
}
.filter-mention-box {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  background-color: #f8f9f9;
  max-height: 350px;
  overflow-y: auto;
}
.mention-item {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mention-item :hover {
  background-color: #f5f5f5;
}

.chat-box-input {
  border: 1px solid #bfb3b3;
  width: 400px;
  margin: auto 0;
  padding: 17px;
  font-size: 13px;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
  margin-left: 5px;
  background-color: #fff;
}

.chat-box-input.chat-box-input-tag {
  color: red;
}

.chat-box-container {
  border: 1px solid #999;
  border-radius: 5px 5px 0px 0px;
  left: 0px;
  bottom: 100%;
  background-color: #e1e3ea;
  z-index: 9999;
  max-height: 115px;
  overflow-y: auto;
}

.chat-box-container.chat-box-info:hover {
  background-color: #f7cccc;
}
.chat-box-container.icon-avatar-boxchat {
  border-radius: 50%;
  max-height: 100%;
}
.chat-box-container.chat-box-info {
  display: flex;
  flex-wrap: wrap;
  height: 30px;
  margin: 2px 0;
}

.chat-box-container.chat-box-info .chat-box-name-user {
  margin: auto 5px;
  width: 190px;
}
.chat-box-container.chat-box-info.chat-box-imfor-wrap-img {
  max-height: 100%;
}

.chat-box-input-tag-user {
  color: #3f51b5;
}

.chat-box-input-tag-nomal {
  color: black;
}

.chat-box-imfor {
  padding: 5px;
}

.chat-box-imfor:hover {
  cursor: pointer;
  /* background-color: rgb(206, 228, 229) !important; */
}

.camera-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.camera-content .stream-screen {
  width: 100%;
  max-width: 80vh;
  margin: 10px 0;
}
.camera-content .stream-screen video,
.stream-screen img {
  width: inherit;
}

.camera-content .devices-composer {
  display: flex;
  justify-content: space-between;
}

.camera-content .select-devices {
  width: 50%;
  margin: 5px;
}

.camera-content .select-devices select {
  display: block;
}

.camera-action > h2 {
  display: flex;
  justify-content: space-between;
}

.camera-action button.dialog-button {
  max-width: 60px;
}

.camera-action h2 div:first-child {
  width: -webkit-fill-available;
}
.camera-action h2 div:first-child button.dialog-button:first-child {
  margin-right: 20px;
}

.chat-box-camera-option
{
  text-align: center;
  height: 40px !important;
  font-size: 18px;
}

.input_safari_ios15
{
bottom: 50px !important;
}
