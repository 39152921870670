.MuiGrid-root.checkbox-copy-drawing {
  width: calc(100% - 280px);
}

.copy-drawing-btn-block {
  width: 280px !important;
  text-align: right;
}

.copy-drawing-btn-block button {
  max-width: 100px;
}

.drawing-area {
  height: 20px;
}

.drawing-block {
  width: 100%;
  height: calc(80vh - 180px);
  min-height: 380px;
  max-height: 560px;
  position: relative;
}

.set-name-mode {
  height: calc(60vh - 180px);
  min-height: 280px;
  max-height: 360px;
  margin: 0 30px;
}

.right-block {
  height: 450px;
  text-align: center;
}

.page-value {
  margin-top: 5px;
  width: calc(50% - 15px);
  max-width: 100px;
  display: inline-flex;
}

.page-value .MuiAutocomplete-clearIndicator {
  /* padding-top: 12px !important;
  padding-bottom: 12px !important; */
  margin-top: 11px !important;
  margin-bottom: 11px !important;
  margin-right: -8px;
}

.page-value .MuiAutocomplete-popupIndicator {
  margin-top: 10px;
  margin-right: -8px;
}

.primary-image-block {
  height: calc(100%);
}

.dialog-copy-drawing {
  /* width: calc(100% - 45px) !important; */
}

.copy-drawing-content {
  overflow: hidden !important;
  padding-top: 20px !important;
}

.copy-drawing-content img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
}

.dialog-copy-title {
  height: 75px;
}

.drawing-border {
  border: 1px solid #c7c7c7;
}

.checkbox-label {
  cursor: pointer;
}

.step-block {
  height: calc(55vh);
  min-height: 345px;
  max-height: calc(100% - 55px);
  border: 1px solid rgba(22, 47, 80, 1);
  padding: 25px;
}

.button-rule {
  width: calc(100% - 40px);
  background-color: rgb(230, 230, 230) !important;
  color: rgba(22, 47, 80, 1) !important;
  height: 45px;
}

.button-rule:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.group-edit-name {
  text-align: left;
}

.input-name {
  width: 100%;
}

.group-button {
  margin: -20px -20px 10px 0;
  text-align: right;
}

.group-button button {
  margin: 5px;
  background-color: rgba(22, 47, 80, 1);
  color: #fff;
  height: 25px;
}

.group-note {
  border: 1px solid rgba(22, 47, 80, 1);
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-step-block {
  margin-bottom: 25px;
}

.name-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawing-name-index {
  width: 20px;
  margin-top: 20px !important;
}

.drawing-name-text,
.drawing-name-text .input-reset {
  width: calc(100% - 20px);
}

.drawing-name-text div.input-reset {
  margin-top: 5px !important;
}

.name-group {
  overflow-y: auto;
  height: calc(100% - 95px);
}

.space-line {
  display: inline-block;
  height: 40px;
  line-height: 80px;
}

.group-button button:hover {
  background-color: rgba(22, 47, 80, 1) !important;
}

@media screen and (max-width: 959px) {
  .copy-drawing-btn-block {
    width: 100% !important;
    text-align: center;
  }

  .copy-drawing-content div:first {
    justify-content: flex-end;
  }

  .dialog-copy-title {
    height: auto;
  }

  .MuiGrid-root.checkbox-copy-drawing {
    width: auto !important;
  }
  .drawing-block {
    min-height: 150px;
    max-height: 250px;
    margin: 0;
  }

  .copy-drawing-content {
    overflow-y: auto !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 599px) {
  .group-edit-floor span {
    display: inline-block;
  }
}

@media screen and (max-width: 451px) {
  .option-area {
    flex-direction: column-reverse;
    align-items: flex-end !important;
  }

  .MuiGrid-root.checkbox-copy-drawing {
    width: 100% !important;
    text-align: left;
  }

  .copy-drawing-btn-block button:not(.MuiAutocomplete-popupIndicator) {
    max-width: 100px;
    min-width: 80px;
    width: auto;
  }
}

@media screen and (max-width: 351px) {
  .checkbox-label {
    width: 70%;
  }
}
