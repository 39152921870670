/* tagFile */

.button-save {
  height: 26px;
  width: 56px;
  background-color: #00346c;
  border-radius: 10%;
  color: white;
  padding: 6px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin-top: 0px;
  cursor: pointer;
  margin-left: 13px;
}

.grid-container {
  height: 50px;
  display: grid;
  grid-template-columns: auto auto auto;

  margin: 0 auto;
}

.grid-item {
  padding: 20px;
  font-size: 14px;
  height: 25px;
  display: flex;
  align-items: center;
}

.item2 {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

.item2:hover {
  background-color: #00346c;
  color: white;
}

.item1:hover {
  cursor: default;
  background-color: #00346c;
  color: white;
}

.item3:hover {
  background-color: red;
  color: white;
}

.upload-file:hover {
  background-color: #c7c7c7;
  padding: 15px;
}

.upload-file > img,
.upload-file > div {
  padding-top: 10px;
}

.no-file {
  border-color: #c7c7c7;
  border-width: 4px;
  background-color: #f5f5f5;
  height: 250px;
  width: 100%;
  border-radius: 8px;
}

.tagfile-img {
  background-color: #f5f5f5;
  height: auto;
  width: 100%;
  position: relative;
}

.active {
  color: #00346c;
  width: 165px;
  display: flex;
  justify-content: center;
}

.container-upload {
  display: flex;
  align-items: center;
}

#fname {
  border-bottom: none;
  border-bottom-style: none;
  padding: 1px 10px;
  margin: 0 !important;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  height: 26px;
  width: 367px;
  cursor: default;
}

.extra-hotspot-info {
  display: flex;
  grid-template-columns: auto auto;
  width: 100%;
}

.btn-dialog-yes {
  /* text-transform: capitalize; */
  height: 45px;
  width: 130px;
  background-color: #00356d;
  border-radius: 5px;
  opacity: 1;
  color: #ffffff;
}

.btn-dialog-no {
  /* text-transform: capitalize; */
  height: 45px;
  width: 130px;
  background-color: #c7c7c7;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
}

.upload-file-next-img {
  z-index: 10;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(0, -50%);
}

.upload-file-previous-img {
  z-index: 10;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translate(0, -50%);
}

.upload-imgs {
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
  right: 0px;
  position: absolute;
  width: 54px;
  background-color: #007cff;
  height: 30px;
}

.text-img {
  text-align: center;
  background-color: #c7c7c7;
  padding: 0px 10px 0px 10px;
  margin: 0px 10px;
}

/* INFO HOTSPOT */
.info-hotspot-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -20px;
  margin-top: -20px;
  /* cursor: default; */
}

.info-hotspot-icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  margin: auto;
  margin-bottom: 8px;
}

.info-hotspot-icon-wrapper div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.info-hotspot-summary-box-wrapper {
  top: 40px;
  left: 40px;

  width: 320px;
  background-color: rgba(0, 52, 107, 0.8);
  border-radius: 14px;
  display: none;

  color: #ffffff;
  padding: 15px;
}

.info-hotspot-summary-box-wrapper .info-hotspot-summary-box-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  padding-bottom: 10px;
  border-bottom: 1px solid white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-hotspot-summary-box-content {
  padding: 10px 0px;
}

.info-hotspot-summary-box-content h3 {
  font-size: 17px;
  font-weight: normal;
  line-height: 23px;
}

.info-hotspot-summary-box-content h3:before {
  content: " ";
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
  margin-right: 8px;
}

.info-hotspot-summary-box-content .info-hotspot-summary-box-description {
  font-size: 15px;
  line-height: 20px;
  color: #09f6ff;
  margin-top: 8px;
  margin-bottom: 10px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.info-hotspot-summary-box-actions {
  display: flex;
  justify-content: space-between;
  height: 20px;
}

.info-hotspot-summary-box-actions div {
  display: flex;
}

.info-hotspot-summary-box-actions div img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.info-hotspot-summary-box-actions button {
  font-size: 15px;
  line-height: 20px;
  text-decoration: underline;
}

.info-hotspot-summary-box-actions button:after {
  content: " ";
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
  margin-left: 8px;
}
.info-hotspot-wrapper.hotspot-info-show-summary {
  margin-left: -160px;
  z-index: 1;
}

.info-hotspot-wrapper.hotspot-info-show-summary .info-hotspot-summary-box-wrapper {
  display: block;
}

.text-info-hotspot {
  position: relative;
  display: inline-block;
}

.text-info-hotspot .tooltip-text {
  visibility: hidden;
  width: 300px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  color: #09f6ff;
  background-color: #00356d;
  padding: 15px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -150px;
}

.text-info-hotspot:hover .tooltip-text {
  visibility: visible;
}

/* TODO thoai: use to create toolltip for hotspot titlle and description in .info-hotspot-summary-box-wrapper */
.hostpot-info-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.hostpot-info-tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.hostpot-info-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.hostpot-info-tooltip:hover .tooltiptext {
  visibility: visible;
}

.link-hotspot:hover .link-hotspot-tooltip {
  display: block !important;
}

.hotspot-link-view-change {
  opacity: 1 !important;
}

.link-hotspot-tooltip {
  display: none !important;
}

.hotspot-link-view-change .link-hotspot-tooltip {
  display: none !important;
}

.show-full .link-hotspot-tooltip {
  display: block !important;
}

.link-hotspot.show-full .link-hotspot-tooltip {
  display: block !important;
}

@media screen and (max-width: 959px) {
  .link-hotspot:hover .link-hotspot-tooltip {
    display: none !important;
  }

  .hotspot-link-view-change {
    opacity: 1 !important;
  }

  .link-hotspot-tooltip {
    display: none !important;
  }

  .hotspot-link-view-change .link-hotspot-tooltip {
    display: none !important;
  }

  .show-full .link-hotspot-tooltip {
    display: block !important;
  }

  .link-hotspot.show-full .link-hotspot-tooltip {
    display: block !important;
  }
}
