.image-viewer {
  width: 100%;
  /*min-height: 100vh;*/
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  min-height: -webkit-fill-available;
  background-color: #000;
  color: #fff;
}
.btn-viewer {
  background-color: #d6d6d6 !important;
  border-radius: 5px;
}

.panolens-canvas {
  height: calc(var(--vh, 1vh) * 100) !important;
}

.parent {
  position: relative;
}

.parent[min-width~="900px"] .btn-viewer {
  background-color: #d6d6d6 !important;
}

.add-task-btn {
  height: 80px;
  width: 66px;
}

.add-share-btn {
  height: 80px;
  width: 66px;
}

.add-share-btn-sp {
  height: 70px;
  width: 46px;
  min-width: 0 !important;
  display: block !important;
}
.img-mini-parent {
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
}
.img-mini-parent-sp {
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 8px 12px !important;
  /* border: 1px dotted #000 !important; */
}
.img-mini-parent-dialog-sp {
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
  /* padding: 0px 12px 8px 12px !important;
  padding: 8px 12px !important; */
  /* border: 1px dotted #000 !important; */
}
.mini-window {
  min-height: 200px;
  min-width: 300px;
  height: 200px;
  width: 300px;
  /* border: #282c34 solid 1px; */
  box-shadow: 0 10px 25px 0 #e5e5e5;
  resize: both;
  overflow: hidden;
  direction: rtl;
  background-color: #e5e5e5d9;
  /*position: absolute;*/
  /*right: 10px;*/
  /*top: 20px;*/
  border-radius: 5px;
  z-index: 10;
  max-height: calc(100vh - 24px);
  max-width: calc(100vw - 10px);
}

.mini-window-parent {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 99;
}

.functions-viewer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  transition: bottom 0.25s ease 0s;
}
.select-viewer {
  background-color: #d6d6d6 !important;
}

.header-item-viewer {
  width: 18vw !important;
}

.header-item-viewer.reset-select {
  width: initial !important;
}

.photographer-title {
  background-color: #d6d6d6;
  border-radius: 5px;
  height: 50px;
  padding: 5px;
  margin-top: 20px;
  word-wrap: break-word;
  overflow: auto;
}

.container-mini-viewer {
  width: 100%;
  height: calc(100% - 25px);
  padding: 2px;
}

.img-mini {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100% !important;
  height: auto !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
  overflow: hidden;
  display: inline-block;
  background-color: #fffffff2;
}

.image-container {
  height: calc(100% - 21px);
}

.image-container div {
  height: 100%;
}

.img-mini-dialog {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: auto;
}
.img-mini-dialog-container img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: auto;
}

.img-adjust {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.adjustImageNum-sp {
  background-color: #e5e5e5d9;
  position: absolute;
  top: 0;
  left: 5px;
}

#primary-image-dialog {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  /* max-height: calc(60vh - 10px); */
  object-fit: contain;
  overflow: hidden;
  display: inline-block;
}

.viewer-content-dialog {
  overflow: hidden;
  /* padding: 8px 12px !important; */
  padding: 0px 12px 16px 12px !important;
}
.viewer-content-dialog-update {
  overflow: hidden;
  /* padding: 8px 12px !important; */
  padding: 0px 12px 16px 12px !important;
  touch-action: manipulation;
}
.map-dialog-content {
  overflow: hidden;
  padding: 0 12px 8px !important;
}

div.react-transform-component,
div.react-transform-element {
  height: 100% !important;
  width: 100% !important;
}

.content-drawing-dialog {
  background-color: #fffffff2;
  border: 1px dotted #000;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.content-drawing-dialog-container {
  background-color: #fffffff2 !important;
  border: 1px dotted #000 !important;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.justify-content-center {
  justify-content: center !important;
}

.viewer-content-dialog .MuiGrid-root,
.map-dialog-content .MuiGrid-root {
  padding-top: 0px !important;
}

.btn-switch {
  min-width: auto !important;
  width: 100%;
  border-radius: 0 !important;
}

/* @media only screen and (min-width: 960px) {
  .btn-switch:hover {
    background-color: #756f70 !important;
  }
} */

.position-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.task-adjust {
  position: absolute;
  top: 5px;
  right: 10px;
}

.btn-add-image-to-point {
  position: fixed;
  background-color: #e5e5e5d9;
  right: 15px;
  bottom: 15px;
  border-radius: 5px;
}

.sub-image-viewer {
  position: relative;
  background-color: #e5e5e5d9;
  width: 136px;
  height: 72px;
  border: 4px solid #162f5000;
  border-radius: 4px;
}

.image-title-viewer {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 560px) {
  .image-title-viewer {
    font-size: 12px !important;
  }
  .image-container {
    height: calc(100% - 30px) !important;
  }
}

.viewer-dialog-title {
  width: calc(100% - 115px);
  text-align: center;
}
.viewer-dialog-footer {
  width: calc(100%);
  text-align: right;
}
.pdr-12 {
  padding-right: 12px;
}
@media screen and (orientation: portrait) {
  .dialog-viewer-header-update {
    padding: 16px 12px !important;
  }
  .portrait-view {
    visibility: visible;
  }
}

@media screen and (orientation: landscape) {
  .portrait-view {
    visibility: hidden;
  }
  .dialog-viewer-header-update-sp {
    padding: 2px 12px !important;
    touch-action: manipulation;
  }
  .dialog-viewer-header-update {
    padding: 16px 12px !important;
  }
}
.dialog-viewer-header-container {
  padding: 0px 12px !important;
}
.dialog-viewer-header-title h6 {
  font-size: 25.5px !important;
}
/* .viewer-dialog-footer div {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.viewer-dialog-title h6 {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-image-adjust {
  background-color: #e5e5e5d9;
  border-radius: 5px;
  padding: 5px !important;
}

.switch-img-adjust {
  background-color: #e5e5e5a6 !important;
}

#img-test {
  height: 300px;
  width: 600px;
}

.marker-selected {
  cursor: pointer;
  color: rgb(250, 130, 39);
  transform: translate(-50%, -85%) !important;
  z-index: 100;
}

.arrow-block-viewer {
  height: 30px;
  width: 30px;
}

.image-landscape-sp {
  display: flex;
  flex-direction: row;
}

.back-btn-viewer {
  /* background-color: #d6d6d6 !important; */
  background-color: #e5e5e5d9 !important;
  position: absolute !important;
  left: 10px;
  top: 15px;
}

.back-btn-creenshot-vr {
  background-color: #00356d !important;
  position: absolute !important;
  left: 10px;
  top: 20px;
}

.text-label {
  margin-top: 20px !important;
  background: #d6d6d6;
  width: 100%;
  height: 50px;
}

.text-label > label {
  color: #162f50 !important;
  padding: 5px;
}

.text-label > .MuiInput-formControl {
  height: 31px;
  padding-left: 5px;
}

.text-label > .MuiInput-formControl > input {
  text-align: center;
  border-bottom: none !important;
}

.text-label > .MuiInput-underline:before,
.text-label > .MuiInput-underline:after {
  display: none;
}

.icon-spot {
  position: absolute;
  color: #00fa00;
}

.icon-spot.no-color:hover {
  cursor: inherit;
  color: #00fa00;
}

.icon-spot-logwalkViewer {
  position: absolute;
  color: #162f50bf;
}
.icon-spot-logwalkViewer:hover {
  color: #162f50;
}

.icon-spot-unactive {
  position: absolute;
  color: #737070;
}
.icon-spot-unactive.no-color:hover {
  cursor: inherit;
  color: #737070;
}

.icon-spot-unactive-logwalkViewer {
  position: absolute;
  color: #00fa0066;
}
.icon-spot-unactive-logwalkViewer:hover {
  color: #00fa0066;
}

.icon-spot-current {
  position: absolute;
  top: -8px;
}

.icon-no-spot {
  color: #ff48f0;
}

.icon-no-spot:hover {
  color: #cc1dbd;
}

.icon-no-spot.MuiSvgIcon-colorDisabled {
  cursor: not-allowed;
}

.icon-no-spot.MuiSvgIcon-colorDisabled:hover {
  color: rgba(0, 0, 0, 0.26);
}

.item-has-shoot {
  font-weight: bold !important;
}

.item-not-shoot {
  color: #808080 !important;
}

.no-image {
  position: absolute;
  top: 50%;
  text-align: center;
  color: white;
  font-size: 20px;
}

.label-adjust-image {
  text-align: center;
  font-size: 16px;
}

.icon-black {
  color: black;
}

@media screen and (max-width: 560px) {
  .functions-viewer {
    width: 100% !important;
    justify-content: flex-end;
  }

  .header-item-viewer {
    width: 70vw !important;
    max-width: 250px !important;
  }

  .mini-window-parent {
    top: 15px;
    right: 8px;
  }

  .mini-window {
    /*top: 15px;*/
    /*right: 8px;*/
    min-width: 168px;
    min-height: 112px;
    width: 168px;
    height: 112px;
    padding-bottom: 10px !important;

    max-height: calc(100vh - 80px) !important;
  }

  .marker-selected {
    transform: translate(-47%, -90%) !important;
  }

  .photographer-title {
    height: auto;
    margin: 0px 0px 12px 0px;
    width: 250px;
    display: block;
  }

  /* .viewer-content-dialog {
    padding-right: 45px !important;
  } */
  /* 
  .viewer-content-dialog .drawing-sp {
    margin-top: 5vh;
  } */

  /* .mini-window::-webkit-resizer {
    border: 2px solid black;
    background: #2c58b3;
  } */

  .marker {
    transform: translate(45%, 110%);
    z-index: 99;
  }

  .icon-spot,
  .icon-spot-current,
  .icon-spot-unactive {
    font-size: 12px !important;
  }

  .icon-spot-current {
    top: -5px;
  }
}
@media screen and (min-width: 560px){
  .custom-Drawer .MuiDrawer-paperAnchorBottom {
    left: 50% !important;
    max-width: 50%;
    transform: translateX(-50%) !important;
  }
}
@media screen and (min-width: 560px) and (max-width: 959px) {
  /* .functions-viewer {
    width: calc(100% - 270px) !important;
  } */
 
  .header-item-viewer {
    width: 210px !important;
  }

  .mini-window-parent {
    top: 24px;
    right: 8px;
  }

  .mini-window {
    /*top: 24px;*/
    /*right: 8px;*/
    min-width: 250px;
    min-height: 150px;
    width: 250px;
    height: 150px;
  }

  .mini-window .image-container {
    max-height: 115px;
  }

  .photographer-title {
    height: auto;
    margin: 10px 10px 0;
    width: 250px;
    display: inline-block;
  }
}

.bottom-area {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 16px);
  transform: translateX(-50%);
  display: flex;
  flex-wrap: "nowrap";
  transition: bottom 0.25s ease 0s;
  touch-action: manipulation;
}

#primary-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: calc(100vh - 120px);
  object-fit: contain;
  overflow: hidden;
  display: inline-block;
}

/*.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {*/
/*  top: 115px!important;*/
/*}*/
/* 
.react-transform-component,
.react-transform-element {
  height: 100%;
  width: 100% !important;
} */

.menu-list
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  width: 250px !important;
}

.menu-list-2
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  width: 320px !important;
  /* top: 245px !important;
  left: 580px !important; */
  overflow: initial;
}

.copy-drawing-container .menu-content {
  /* height: calc(100vh - 180px); */
  height: 250px;
  overflow-y: scroll;
  margin-left: 10px;
}

.feature-flag-detail-dialog .menu-content {
  /* height: calc(100vh - 180px); */
  height: 350px !important;
}

.copy-drawing-container-sp .menu-content {
  height: calc(100vh - 180px);
  max-height: 250px;
  overflow-y: scroll;
  margin-left: 10px;
  z-index: 1900;
}

.menu-note {
  font-weight: bold !important;
}

.progress-bar {
  width: 70%;
  text-align: center;
  display: inline-block;
}

.hand-move {
  height: 20px;
  position: sticky;
  top: 0;
  z-index: 111;
  margin-bottom: 2px;
}

.menu-item li {
  border-bottom: 1px dashed !important;
  padding-left: 0 !important;
  display: inline-block !important;
  width: calc(100% - 21px) !important;
}

.menu-item:last-child li {
  border-bottom: none !important;
}

.item-icon {
  width: 21px;
  height: inherit;
  display: inline-block;
  line-height: 10px;
  position: relative;
}

.icon-selected {
  position: absolute;
  left: 0;
  top: -15px;
  color: rgb(255, 89, 0);
}

.menu-item li .item-name {
  width: 100%;
  margin-left: 5px;
}
.table-remove-overflow .MuiTableHead-root{
  background-color: #162f50 !important;
}
.MuiTableCell-body{
  border-radius: 0;
}
.MuiTableCell-stickyHeader{
  background-color: #162f50 !important;
  border-left: 1px solid #162f50 !important;
  border-right: 1px solid #162f50 !important;
  border-bottom: 1px solid #162f50 !important;
  outline: none;
  border-radius: 0;
  /* top: 79px !important; */
}
.table-remove-overflow.MuiTableContainer-root{
  overflow-x: inherit;
}
@media screen and (max-width: 959px) {
  .MuiTableCell-stickyHeader{
    /* top: 63px !important; */
  }
}
@media only screen and (max-width: 960px) {
  .container-mini-viewer {
    height: 100%;
    padding: 0;
  }

  .image-container {
    height: calc(100% - 15px);
  }

  .menu-list-2 {
    height: 500px;
    overflow-y: scroll;
  }
  .menu-list-2
    .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    top: 20% !important;
    overflow: initial;
  }

  .map-option-dialog .MuiFab-root.close-button-2 {
    right: -15px !important;
  }

  .MuiButtonBase-root.selected-photos {
    top: 5px;
    right: 5px;
    transform: scale(1.8);
  }

  .item-checkbox {
    width: 30px !important;
    height: 20px !important;
  }

  .menu-item li {
    width: calc(100% - 30px) !important;
  }
}

@media screen and (max-width: 600px) {
  .menu-item li .item-name {
    line-height: 35px;
  }

  .mini-window {
    resize: unset;
    direction: unset;
  }
}

.btn-choose {
  color: #fff !important;
  background-color: rgb(75, 75, 75) !important;
}

#pano {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#titleBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 40px;
  height: 40px;
  text-align: center;
}

@media (max-width: 575.98px) {
  #titleBar {
    height: 50px;
    right: 50px;
  }

  #titleBar .sceneName {
    line-height: 40px;
  }
}

/* If there is a fullscreen button the title bar must make space for it */
body.fullscreen-enabled #titleBar {
  right: 80px;
}

/* If there are multiple scenes the title bar must make space for the scene list toggle */
body.multiple-scenes #titleBar {
  left: 40px;
}

#titleBar .sceneName {
  width: 100%;
  height: 100%;
  line-height: 30px;
  padding: 5px;
  background-color: rgb(58, 68, 84);
  background-color: rgba(58, 68, 84, 0.8);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* Link hotspot */

.link-hotspot {
  width: 60px;
  height: 36px;
  padding: 4px 0px;
  margin-left: -30px;
  margin-top: -18px;
  opacity: 0.65;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.link-hotspot:hover {
  opacity: 1;
}

.link-hotspot-icon {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.link-hotspot-icon-close{
  width: 30px;
  height: 30px;
  top: 15px;
  cursor: pointer;
  position: relative;
  left: 15px;

}
.link-hotspot-tooltip {
  position: absolute;
  left: 50%;
  top: 95%;

  font-size: 16px;

  max-width: 300px;

  padding: 8px 10px;

  border-radius: 5px;

  /* background-color: rgb(58, 68, 84); */
  background-color: rgba(58, 68, 84, 0.65);

  color: #fff;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  cursor: pointer;

  opacity: 1;

  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  /* -webkit-transition: -ms-transform 0.3s, -webkit-transform 0.3s, transform 0.3s;
  transition: -ms-transform 0.3s, -webkit-transform 0.3s, transform 0.3s; */
}


.link-hotspot:hover .link-hotspot-tooltip {
  opacity: 1;
  /* -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
  transform: translateX(0); */
}

/* Prevent tooltip from triggering */
.link-hotspot-tooltip {
  pointer-events: none;
}
.link-hotspot:hover .link-hotspot-tooltip {
  pointer-events: all;
}

/* Fallback mode without pointer-events (IE8-10) */
.tooltip-fallback .link-hotspot-tooltip {
  display: none;
}
.tooltip-fallback .link-hotspot:hover .link-hotspot-tooltip {
  display: block;
}

/* Info hotspot */

.info-hotspot {
  line-height: 1.2em;
  z-index: 20;
  opacity: 0.9;
  -webkit-transition: opacity 0.2s 0.2s;
  transition: opacity 0.2s 0.2s;
}

.info-hotspot:hover {
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.info-hotspot.visible {
  opacity: 1;
}

.info-hotspot .info-hotspot-header {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: rgb(103, 115, 131);
  cursor: pointer;
  -webkit-transition: width 0.3s ease-in-out 0.5s,
    border-radius 0.3s ease-in-out 0.5s;
  transition: width 0.3s ease-in-out 0.5s, border-radius 0.3s ease-in-out 0.5s;
}

@media (min-width: 576px) {
  .info-hotspot .info-hotspot-header:hover {
    width: 260px;
    border-radius: 5px;
    -webkit-transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
    transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
  }

  .info-hotspot.visible .info-hotspot-header,
  .info-hotspot.visible .info-hotspot-header:hover {
    width: 260px;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    -webkit-transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
    transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
  }

  .hotspot-menu:hover .btn-add-tooltip {
    width: 300px;
    border-radius: 5px;
    padding: 10px;
    display: block;
    transition: width 0.3s, padding 0.3s, border-radius 0.3s;
  }
}

.info-hotspot .info-hotspot-icon-wrapper {
  width: 464px;
  height: 226px;
}

.info-hotspot .info-hotspot-icon {
  width: 40px;
  height: 40px;
  background-color: #0066ff;
  border-radius: 50%;
}

.info-hotspot .info-hotspot-title-wrapper {
  position: absolute;
  left: 40px;
  top: 0;
  width: 0;
  height: 40px;
  padding: 0;
  overflow: hidden;
  -webkit-transition: width 0s 0.4s, padding 0s 0.4s;
  transition: width 0s 0.4s, padding 0s 0.4s;
}

@media (min-width: 576px) {
  .info-hotspot.visible .info-hotspot-title-wrapper,
  .info-hotspot .info-hotspot-header:hover .info-hotspot-title-wrapper {
    width: 220px;
    padding: 0 5px;
    -webkit-transition: width 0s 0.4s, padding 0s 0.4s;
    transition: width 0s 0.4s, padding 0s 0.4s;
  }

  .hotspot-menu:hover .info-hotspot-header {
    width: 260px;
    border-radius: 5px;
    transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
  }

  .hotspot-menu:hover .info-hotspot-title-wrapper {
    width: 220px;
    padding: 0 5px;
    -webkit-transition: width 0s 0.4s, padding 0s 0.4s;
    transition: width 0s 0.4s, padding 0s 0.4s;
  }
}

.info-hotspot .info-hotspot-title-wrapper:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.info-hotspot .info-hotspot-title {
  display: inline-block;
  vertical-align: middle;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.info-hotspot .info-hotspot-close-wrapper {
  position: absolute;
  left: 260px;
  top: 0;
  height: 40px;
  width: 40px;
  border-top-right-radius: 5px;
  background-color: rgb(78, 88, 104);
  visibility: hidden;
  -ms-transform: perspective(200px) rotateY(90deg);
  -webkit-transform: perspective(200px) rotateY(90deg);
  transform: perspective(200px) rotateY(90deg);
  -ms-transform-origin: 0 50% 0;
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
  -webkit-transition: -ms-transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s,
    transform 0.3s 0.3s, visibility 0s 0.6s;
  transition: -ms-transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s,
    transform 0.3s 0.3s, visibility 0s 0.6s;
}

@media (min-width: 576px) {
  .hotspot-menu:hover .info-hotspot-close-wrapper {
    visibility: visible;
    -ms-transform: perspective(200px) rotateY(0deg);
    -webkit-transform: perspective(200px) rotateY(0deg);
    transform: perspective(200px) rotateY(0deg);
    -webkit-transition: -ms-transform 0.4s 0.3s, -webkit-transform 0.4s 0.3s,
      transform 0.4s 0.3s, visibility 0s 0s;
    transition: -ms-transform 0.4s 0.3s, -webkit-transform 0.4s 0.3s,
      transform 0.4s 0.3s, visibility 0s 0s;
  }

  .info-hotspot.visible .info-hotspot-close-wrapper {
    visibility: visible;
    -ms-transform: perspective(200px) rotateY(0deg);
    -webkit-transform: perspective(200px) rotateY(0deg);
    transform: perspective(200px) rotateY(0deg);
    -webkit-transition: -ms-transform 0.4s 0.3s, -webkit-transform 0.4s 0.3s,
      transform 0.4s 0.3s, visibility 0s 0s;
    transition: -ms-transform 0.4s 0.3s, -webkit-transform 0.4s 0.3s,
      transform 0.4s 0.3s, visibility 0s 0s;
  }
}

.info-hotspot .info-hotspot-close-icon,
.info-hotspot .info-hotspot-check-icon {
  width: 70%;
  height: 70%;
  margin: 15%;
}

.info-hotspot .info-hotspot-text {
  position: absolute;
  width: 300px;
  height: auto;
  max-height: 200px;
  top: 40px;
  left: 0;
  padding: 10px;
  background-color: rgb(58, 68, 84);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow-y: auto;
  visibility: hidden;
  /* rotate(90deg) causes transition flicker on Firefox 58 */
  -ms-transform: perspective(200px) rotateX(-89.999deg);
  -webkit-transform: perspective(200px) rotateX(-89.999deg);
  transform: perspective(200px) rotateX(-89.999deg);
  -ms-transform-origin: 50% 0 0;
  -webkit-transform-origin: 50% 0 0;
  transform-origin: 50% 0 0;
  -webkit-transition: -ms-transform 0.3s, -webkit-transform 0.3s, transform 0.3s,
    visibility 0s 0.3s;
  transition: -ms-transform 0.3s, -webkit-transform 0.3s, transform 0.3s,
    visibility 0s 0.3s;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@media (min-width: 576px) {
  .info-hotspot.visible .info-hotspot-text {
    visibility: visible;
    -ms-transform: perspective(200px) rotateX(0deg);
    -webkit-transform: perspective(200px) rotateX(0deg);
    transform: perspective(200px) rotateX(0deg);
    -webkit-transition: -ms-transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s,
      transform 0.3s 0.3s, visibility 0s 0s;
    transition: -ms-transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s,
      transform 0.3s 0.3s, visibility 0s 0s;
  }

  .hotspot-menu.visible .info-hotspot-text {
    visibility: visible;
    -ms-transform: perspective(200px) rotateX(0deg);
    -webkit-transform: perspective(200px) rotateX(0deg);
    transform: perspective(200px) rotateX(0deg);
    -webkit-transition: -ms-transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s,
      transform 0.3s 0.3s, visibility 0s 0s;
    transition: -ms-transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s,
      transform 0.3s 0.3s, visibility 0s 0s;
  }

  .hotspot-menu.visible .btn-add-tooltip {
    visibility: hidden;
  }

  .hotspot-menu.visible .info-hotspot-text {
    visibility: visible;
    -ms-transform: perspective(200px) rotateX(0deg);
    -webkit-transform: perspective(200px) rotateX(0deg);
    transform: perspective(200px) rotateX(0deg);
    -webkit-transition: -ms-transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s,
      transform 0.3s 0.3s, visibility 0s 0s;
    transition: -ms-transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s,
      transform 0.3s 0.3s, visibility 0s 0s;
  }
}

/* Info hotspot modal */
@media (min-width: 576px) {
  .info-hotspot-modal {
    display: none;
  }
}

.info-hotspot-modal {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 11000 !important;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  line-height: 1.2em;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s ease-in-out 0.5s, visibility 0s 0.7s;
  transition: opacity 0.2s ease-in-out 0.5s, visibility 0s 0.7s;
}

.info-hotspot-modal.visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0s 0s;
  transition: opacity 0.2s ease-in-out, visibility 0s 0s;
}

.info-hotspot-modal .info-hotspot-header {
  position: absolute;
  top: 60px;
  left: 10px;
  right: 10px;
  width: auto;
  height: 50px;
  background-color: rgb(103, 115, 131);
  background-color: rgba(103, 115, 131, 0.8);
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out 0.2s;
  transition: opacity 0.3s ease-in-out 0.2s;
}

.info-hotspot-modal.visible .info-hotspot-header {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out 0.2s;
  transition: opacity 0.3s ease-in-out 0.2s;
}

.info-hotspot-modal .info-hotspot-icon-wrapper {
  width: 50px;
  height: 50px;
}

.info-hotspot-modal .info-hotspot-icon {
  width: 90%;
  height: 90%;
  margin: 5%;
}

.info-hotspot-modal .info-hotspot-title-wrapper {
  position: absolute;
  top: 0;
  left: 50px;
  right: 50px;
  width: auto;
  height: 50px;
  padding: 0 10px;
}

.info-hotspot-modal .info-hotspot-title-wrapper:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.info-hotspot-modal .info-hotspot-title {
  display: inline-block;
  vertical-align: middle;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.info-hotspot-modal .info-hotspot-close-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: rgb(78, 88, 104);
  background-color: rgba(78, 88, 104, 0.8);
  cursor: pointer;
}

.info-hotspot-modal .info-hotspot-close-icon {
  width: 70%;
  height: 70%;
  margin: 15%;
}

.info-hotspot-modal .info-hotspot-text {
  position: absolute;
  top: 110px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding: 10px;
  background-color: rgb(58, 68, 84);
  background-color: rgba(58, 68, 84, 0.8);
  overflow-y: auto;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.info-hotspot-modal.visible .info-hotspot-text {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out 0.4s;
  transition: opacity 0.3s ease-in-out 0.4s;
}

/* View control buttons */

.viewControlButton {
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: rgb(103, 115, 131);
  background-color: rgba(103, 115, 131, 0.8);
}

body.view-control-buttons .viewControlButton {
  display: block;
}

/* Hide controls when width is too small */
@media (max-width: 600px) {
  body.view-control-buttons .viewControlButton {
    display: none;
  }
}

.viewControlButton .icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
}

/* Center is at margin-left: -20px */
.viewControlButton-1 {
  margin-left: -145px;
}
.viewControlButton-2 {
  margin-left: -95px;
}
.viewControlButton-3 {
  margin-left: -45px;
}
.viewControlButton-4 {
  margin-left: 5px;
}
.viewControlButton-5 {
  margin-left: 55px;
}
.viewControlButton-6 {
  margin-left: 105px;
}

.info-hotspot .btn-add-tooltip {
  cursor: pointer;
  overflow: hidden;
  width: 0px;
  height: 40px;
  max-height: 200px;
  top: 40px;
  left: 0;
  border-radius: 5px;
  padding: 10px 0px;
  background-color: rgb(58, 68, 84);
  transition: width 0.3s ease-in-out 0.5s, border-radius 0.3s ease-in-out 0.5s,
    padding 0.3s ease-in-out 0.5s;
}

.btn-add-tooltip:hover {
  background-color: #b6b6b6;
}

.text-area-input {
  color: #fff;
  width: 250px;
  height: 150px;
}

.action {
  width: 25px;
  height: 25px;
  margin-left: auto;
  fill: #fff;
  display: inline-block;
  cursor: pointer;
}

.action img {
  width: 15px;
  height: 15px;
}

.info-hotspot .photo-tag {
  width: 100%;
  height: auto;
  max-height: 180px;
  object-fit: contain;
}

.info-hotspot-remove {
  visibility: visible;
  width: 25px;
  height: 25px;
  top: 10px;
  position: absolute;
  left: -30px;
  background-color: #000;
  /* opacity: 0.8; */
  cursor: pointer;
  border-radius: 50%;
  padding-right: 20px;
}

/* .info-hotspot.visible .info-hotspot-header .info-hotspot-remove, */
.info-hotspot:hover .info-hotspot-remove {
  visibility: visible;
  background-color: #333;
  -webkit-transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, -webkit-transform 200ms;
  -o-transition: opacity 200ms, -o-transform 200ms;
  -moz-transition: transform 200ms, opacity 200ms, -moz-transform 200ms;
  transition: transform 200ms, opacity 200ms;
  transition: transform 200ms, opacity 200ms, -webkit-transform 200ms,
    -moz-transform 200ms, -o-transform 200ms;
  transition-delay: 1s;
}

.info-hotspot-remove:hover {
  visibility: visible;
}

.info-hotspot-remove img {
  position: absolute;
  width: 12px;
  height: 15px;
  top: 50%;
  transform: translate(50%, -50%);
}

.input-tag {
  background-color: #d0e0f0;
  border-radius: 4px;
  color: rgb(0, 107, 247);
  font-weight: bold;
}

/* handle link|info hotspot for mobile */
@media (max-width: 575.98px) {
  /* add new info hotspot, defined by .hotspot-menu class */
  .info-hotspot.hotspot-menu {
  }

  .info-hotspot.hotspot-menu > .info-hotspot-header {
    width: 40px;
    height: 40px;
    background-color: rgb(103, 115, 131);
    border-radius: 0px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .info-hotspot.hotspot-menu
    > .info-hotspot-header
    > .info-hotspot-icon-wrapper {
    width: 100%;
    height: 100%;
  }

  .info-hotspot.hotspot-menu
    > .info-hotspot-header
    > .info-hotspot-icon-wrapper
    > .info-hotspot-icon {
  }

  .info-hotspot.hotspot-menu
    > .info-hotspot-header
    > .info-hotspot-title-wrapper {
    position: absolute;
    left: 40px;
    top: 0px;
    height: 40px;
    width: 220px;
    padding: 0 5px;
    -webkit-transition: width 0s 0.4s, padding 0s 0.4s;
    transition: width 0s 0.4s, padding 0s 0.4s;
    background-color: rgb(103, 115, 131);
  }

  .info-hotspot.hotspot-menu
    > .info-hotspot-header
    > .info-hotspot-close-wrapper {
    -ms-transform: perspective(200px) rotateY(0deg);
    -webkit-transform: perspective(200px) rotateY(0deg);
    transform: perspective(200px) rotateY(0deg);
    -webkit-transition: -ms-transform 0.4s 0.3s, -webkit-transform 0.4s 0.3s,
      transform 0.4s 0.3s, visibility 0s 0s;
    transition: -ms-transform 0.4s 0.3s, -webkit-transform 0.4s 0.3s,
      transform 0.4s 0.3s, visibility 0s 0s;
    visibility: visible;
    top: 0px;
  }

  .info-hotspot.hotspot-menu > .btn-add-tooltip {
    width: 300px;
    border-radius: 5px;
    padding: 10px;
    display: block;
    transition: width 0.3s, padding 0.3s, border-radius 0.3s;
    background-color: #b6b6b6;
  }
}

/* ViewerInfoArePC */

.headerIconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
}

.headerIcon {
  width: 30px;
  height: 30px;
  margin: 0px 5px;
  border-radius: 30px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
}

.viewr-center {
  display: flex;
}

/* HotstPotsHeader */

.header-button:hover {
  background-color: #00346c;
  color: #ffffff;
  border-radius: 18%;
}

.header-button {
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}

.header-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  background-color: #94c79b;
  border-radius: 50%;
}

.header-button-menu {
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
  background-color: #00346c;
  color: #ffffff;
  border-radius: 18%;
}

/* tagFile */

.button-save {
  height: 27px;
  width: 56px;
  background-color: #00346c;
  border-radius: 10%;
  color: white;
  padding: 6px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin-top: 13px;
  cursor: pointer;
  margin-left: 35px;
}

.grid-container {
  height: 50px;
  display: grid;
  grid-row-gap: 50px;
  grid-template-columns: auto auto auto;
  /* margin: 0px 15px 15px; */
}

.grid-item {
  padding: 20px;
  font-size: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 25px;
}

.item2 {
  border-left: 1px solid #00346c;
  border-right: 1px solid #00346c;
}

.item2:hover {
  background-color: #00346c;
  color: white;
}

.item1:hover {
  background-color: #00346c;
  color: white;
}

.item3:hover {
  background-color: red;
  color: white;
}

.history-control {
  background-color: #e5e5e5d9;
  height: 32px;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 3px;
}

.history-control .current-history {
  display: inline-block;
  width: 175px;
}

.no-zoom,
.no-zoom * {
  touch-action: manipulation !important;
}

.history-picker {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  background-color: #e5e5e5eb !important;
  z-index: 1;
  transition: bottom 0.25s ease 0s;
}

.time-picker-pc {
  position: fixed;
  transform: translate(-10px, -80%);
  background-color: #e5e5e5eb;
  border-radius: 10px;
  width: 110px;
  text-align: center;
  padding: 10px;
  z-index: 999;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
}

.time-picker-adjust-pc {
  position: fixed;
  transform: translate(-95%, -20%);
  background-color: #e5e5e5eb;
  border-radius: 10px;
  width: 110px;
  text-align: center;
  padding: 10px;
  z-index: 999;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
}

.time-picker {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #e5e5e5eb;
  border-radius: 10px;
  width: 110px;
  text-align: center;
  padding: 10px;
}

.time-picker-pc .time-items .time-item,
.time-picker-adjust-pc .time-items .time-item {
  width: 90px;
  max-height: 120px;
  overflow-y: auto;
}

.time-picker-pc .time-items .time-item,
.time-picker-adjust-pc .time-items .time-item,
.time-picker .time-items .time-item {
  height: 35px;
  cursor: pointer;
  line-height: 35px;
}

.time-picker-pc .time-items .time-item:hover,
.time-picker-adjust-pc .time-items .time-item:hover,
.time-picker .time-items .time-item:hover {
  background-color: #8b9898 !important;
  color: #e5e5e5d9;
}

.time-items {
  max-height: 120px;
  overflow-y: auto;
  overflow-x: hidden;
}

.DayPicker-Day {
  padding: 0.35em 0.5em !important;
}

.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  /* background-color: rgba(22, 47, 80, 1) !important;
  color: #e5e5e5d9 !important;
  cursor: pointer; */
}

.DayPicker-Day--highlighted {
  background-color: rgba(22, 47, 80, 1) !important;
  color: #e5e5e5d9 !important;
  cursor: pointer;
}

.DayPicker-Caption {
  background-color: rgba(22, 47, 80, 1) !important;
  color: #e5e5e5d9 !important;
  border-radius: 5px;
}

.DayPicker-NavButton {
  top: 1.1rem !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: rgb(169, 46, 63) !important;
  color: #e5e5e5d9;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: rgb(169, 46, 63) !important;
  color: #e5e5e5d9 !important;
}

.DayPicker-Day--today {
  color: #e91000 !important;
}

.DayPicker-Day--disabled {
  position: relative;
  color: #888888 !important;
  cursor: not-allowed !important;
}

.DayPicker-Day--disabled::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 2px;
  background-color: rgb(153 153 153);
  transform: rotate(-20deg) translateX(-50%);
  top: 40%;
  left: 50%;
}
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--highlighted):not(.DayPicker-Day--selected):hover {
  background-color: #808080 !important;
}

.task-adjust .history-picker {
  bottom: auto !important;
  left: auto !important;
  z-index: 999;
  top: 50px;
  right: 0%;
  transform: translateX(0);
}

.task-adjust .time-picker {
  bottom: auto !important;
  left: auto !important;
  z-index: 999;
  top: 50px;
  right: 0%;
  /* transform: translateX(0); */
}

/* Create Hotspot */
.info-hotspot .info-hotspot-header-create {
  width: 464px;
  height: 226px;
}

.info-hotspot-icon-wrapper:hover .btn-dialog-hotspot {
  border-radius: 18px;
  background-color: #00356de6;
  height: 170px;
  width: 380px;
  margin-left: 40px;
  position: relative;
}

.btn-dialog-hotspot {
  border-radius: 18px;
  background-color: #00356de6;
  height: 170px;
  width: 380px;
  margin-left: 40px;
  position: relative;
}

.info-hotspot-header-create:hover .btn-dialog-hotspot {
  height: 170px;
  width: 380px;
}

.info-hotspot-title-create-wrapper {
  border-radius: 15px;
  margin-left: 42px;
  background-color: #00356d;
  width: 370px;
  height: 166px;
  display: none;
}

.info-hotspot :hover .info-hotspot-title-create-wrapper {
  display: block;
}

.info-hotspot-header-dialog {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding-top: 25px;
  padding-left: 12px;
  height: 30%;
  background-color: #00356d;
  display: flex;
  border-bottom: 2px solid #ffffff;
  font-size: 17px;
}

.info-hotspot-title-create-wrapper .info-hotspot-content-dialog {
  height: auto;
}

.info-hotspot-content-icon {
  margin-top: 14px;
  display: flex;
}

.info-hotspot-icon-next {
  margin-left: 12px;
  width: 7px;
}

.text-content {
  padding-left: 10px;
}

.date-content {
  padding-left: 18px;
}

.info-hotspot-content-text {
  padding-left: 12px;
  padding-top: 16px;
  color: #0066ff;
}

.info-hotspot-content-footer {
  padding-right: 10px;
  padding-top: 14px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.content-footer {
  display: flex;
}

.mesage-footer {
  width: 25px;
}

.upload-footer {
  width: 25px;
  margin-left: 5px;
}

.back-footer {
  margin-top: 0px;
  display: flex;
}

.text-left-footer {
  margin-right: 4px;
  padding-top: 11px;
  text-decoration-line: underline;
  color: #ffffff;
}

.next-footer {
  padding-top: 11px;
  width: 7px;
}

.button-history-newest {
  background-color: rgba(22, 47, 80, 1) !important;
  color: #e5e5e5d9 !important;
}

/* temp hotspot */
.temp-hotspot {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -20px;
  margin-left: -20px;
  z-index: 999;
}

.temp-hotspot img {
  width: 40px;
  height: 40px;
}

.container-history-calendar {
  touch-action: manipulation;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 100;
}

/* cursor icon when create hotspot */
.create-hotspot-cursor {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  display: none;
  cursor: none;
}
.image-viewer:hover ~ .create-hotspot-cursor {
  display: initial;
}
@media (hover: none) {
  .create-hotspot-cursor {
    display: none !important;
  }
}

.hide-cursor > div {
  cursor: none;
}

/* CSS for unadjusted image */
.sprite {
  height: 64px;
  display: table-cell;
  background-repeat: repeat;
}
.sprite.img0 {
  background-position: -32px -576px;
  width: 32px;
  background-position-x: right;
  background-size: 200%;
}
.sprite.img1 {
  width: 64px;
  background-position: 0 -128px;
  background-size: 100%;
}
.sprite.img2 {
  background-position: -32px -640px;
  width: 32px;
  background-position-x: left;
  background-size: 200%;
}

/* Remove blue ouline on mobile: react-day-picker */
.DayPicker * {
  outline: none !important;
}

.logwalk-viewer-container {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  overflow-y: hidden !important;
  touch-action: manipulation;
  height: fit-content;
}

.image-hotspot {
  position: absolute !important;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  opacity: 1 !important;
  z-index: 2;
}

.hotspot-mini-block canvas {
  z-index: -1 !important;
}

.converting-photo {
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  background-color: #162f50;
  color: #fff;
  opacity: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  z-index: 1;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.4s, opacity 0.4s linear;
  -moz-transition: visibility 0s linear 0.4s, opacity 0.4s linear;
  -ms-transition: visibility 0s linear 0.4s, opacity 0.4s linear;
  -o-transition: visibility 0s linear 0.4s, opacity 0.4s linear;
  transition: visibility 0s linear 0.4s, opacity 0.4s linear;
}

.converting-photo.show {
  visibility: visible;
  opacity: 0.6;
  transition-delay: 0s;
}

button.toggle-show-hotspot-info{
  content: " ";
  position: relative;
  display: inline-block;
  user-select: none;
  transition: 0.4s ease;
  height: 30px;
  width: 50px;
  border-radius: 9999px;
  background-color: #B0B0B0;
}

button.toggle-show-hotspot-info:before {
  content: "";
  position: absolute;
  display: block;
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 30px;
  width: 50px;
  top: 0px;
  left: 0px;
  border-radius: 30px;
}

button.toggle-show-hotspot-info:after {
  content: "";
  position: absolute;
  display: block;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0px 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13),
      0 3px 3px rgba(0, 0, 0, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: whitesmoke;
  height: 26px;
  width: 26px;
  top: 2px;
  left: 2px;
  border-radius: 9999px;
}

button.toggle-show-hotspot-info.show-hotspot-info:before {
  background: #2ecc71;
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}

button.toggle-show-hotspot-info.show-hotspot-info:after {
  left: 22px;
}

.walk-through-options-lw {
  width: 100%;
  border-radius: 6px;
  background-color: #e5e5e5d9;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 14px;
  touch-action: manipulation;
}

.walk-through-options-lw .walk-through-option-item {
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  cursor: pointer;
}

.walk-through-options-lw hr {
  width: 70%;
  height: 2px;
  background-color: #787878;
  border-radius: 9999px;
  margin: 0px 0px 0px 5px;
  border: none;
}

.show-direction-block-btn {
  width: 100px;
  position: absolute !important;
  bottom: 0 !important;
  left: 50%;
  padding-bottom: 0 !important;
  transform: translateX(-50%);
}

.walk-through-edit-link-icon {
  width: 50px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.walk-through-edit-link-icon span{
  /* width: 32px;
  height: 32px; */
  border-radius: 9999px;
  /* overflow: hidden; */
  /* background-color: #162f50; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 36px;
  line-height: 30px;
}
.walk-through-edit-link-icon img{
  width: 40px;
}
