.drawing-title-dialog {
  height: 40px;
  width: calc(100% - 115px) !important;
  text-align: center;
  /* width: 50% !important; */
  /* border: 2px solid #5154eb; */
}
.drawing-title-dialog h4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 25.5px;
}

.content-drawing-dialog {
  background-color: #e5e5e5f2 !important;
  border: 1px solid #000 !important;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media screen and (orientation:portrait) {
  .content-drawing-dialog-sp {
    background-color: #FFFFFFF2 !important;
    border: 1px dotted #000 !important;
    width: calc(90vw - 45px);
    height:calc(90vw - 45px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    /* height: calc(56vh - 50px); */
    /* max-height: 90vw;  */
    min-width: 160px;
    min-height: 160px;
  } 
}
/* and (max-width: 560px) */
@media screen and (max-width: 850px) and (orientation:landscape) {
  .icon-spot {
    padding: 0.215em !important;
  }
  .icon-spot-unactive{
    padding: 0.215em !important;
  }
}
@media screen and (max-width: 568px) and (orientation:landscape) {
  .content-drawing-dialog-sp {
    background-color: #FFFFFFF2 !important;
    border: 1px dotted #000 !important;
    width: calc(85vh - 50px) !important;
    height: calc(85vh - 50px) !important;
    max-height: 400px; 
    max-width: 400px;
    min-width: 160px;
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
  }
}
@media screen  and (orientation:landscape) {
  .content-drawing-dialog-sp {
    background-color: #FFFFFFF2 !important;
    border: 1px dotted #000 !important;
    width: calc(85vh - 130px);
    height: calc(85vh - 130px);
    /* max-height: 280px;
    max-width: 280px;
    min-width: 160px;
    min-height: 160px; */
    /* max-height: 400px; 
    max-width: 400px; */
    min-width: 160px;
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
  }
  .drawing-dialog-btn-landscape{
    display: flex;
    flex-direction: column;
    padding-top: 27vh !important;
  }
  .drawing-dialog-btn-adjust{
    margin-top: 27vh !important;
    width: 100%;
    text-align: right !important;
  }
}
.dialog-title-landscap-w100{
  width: 100%;
}
.drawing-dialog-content-landscap{
  padding: 2px 0px !important;
  /* padding: 2px 12px !important; */
}
.justify-content-flex-start {
  justify-content: flex-start !important;
}
.justify-content-flex-end {
  justify-content: flex-end !important;
}
.img-drawingphoto-parent-sp {
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
  /* border: 1px dotted #000 !important; */
}
.img-mini-drawingdialog {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: auto;
}
.drawing-dialog{
  /* background-color: #FFFFFFF2 !important; */
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0px !important;
}
.drawing-dialog-new {
  margin: 0px !important;
}
.drawing-dialog-bg{
  background-color: #FFFFFFF2 !important;
  touch-action: manipulation;
}
.drawing-dialog-title {
  width: calc(100% - 100px);
  text-align: center;
}

@media screen and (orientation: landscape) {
  .drawing-dialog-title {
    width: calc(100% - 50px);
    text-align: center;
  }
}
.drawing-dialog-title h6 {
  text-align: center;
  text-overflow: ellipsis;

}
.text-align-left {
  text-align: left !important;

}

.MuiGrid-root.MuiGrid-root#primary-map {
  width: 100%;
  height: calc(100vh - 157px);
  background-color: #efefef;
}

#primary-drawing {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  /* max-height: calc(60vh - 10px); */
  object-fit: contain;
  overflow: hidden;
  display: inline-block;
}

.menu-drawings {
  position: absolute !important;
  top: 15px;
  right: 15px;
}
.menu-item-container{
  width: 100% ;
}
.menu-item{
  padding: 0px !important;
}

.menu-item li {
  border-bottom: 1px dashed !important;
  padding-left: 0 !important;
  display: inline-block !important;
  width: calc(100% - 21px) !important;
}
.menu-item .item-text {
  border-bottom: 1px dashed !important;
  padding-left: 0 !important;
  display: inline-block !important;
  width: calc(100% - 21px) !important;
  
  
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}


.menu-item:last-child li {
  border-bottom: none !important;
}

.item-icon {
  width: 21px;
  height: inherit;
  display: inline-block;
  line-height: 10px;
  position: relative;
}

.icon-selected {
  position: absolute;
  left: 0;
  top: -15px;
  color: rgb(255, 89, 0);
}

.item-checkbox {
  width: 21px;
  height: 15px;
  display: inline-block;
  line-height: 10px;
  position: relative;
}

.menu-item li .item-name {
  width: 100%;
  margin-left: 5px;
}
.menu-item li {
  padding-bottom: 0px !important;
  margin-left: 5px;
}
.menu-item li .item-name {
  width: 100%;
  margin-left: 5px;
}
.item-text {
  width: 100%;
  margin-left: 5px;
}
.item-text {
  width: 100%;
  margin-left: 5px;
}

@media screen and (max-width: 560px) {
  .menu-item {
   padding-top: 10px !important;
  }
}

.btn-choose {
  color: #fff !important;
  background-color: rgb(75, 75, 75) !important;
}

.container-mobile-header{
  background-color: transparent !important;
  box-shadow:none !important;
  /* padding:16px 24px !important; */
}
.container-mobile-footer{
  background-color: transparent !important;
  box-shadow:none !important;
  /* padding:16px 24px !important; */
}
.padding-bottom-8{
  padding-bottom:8px!important;
}
.drawing-dialog-footer{
  padding-top: 8px !important;
}
 button:focus .btn-choose-dialog-viewer-header,
 button:focus .btn-choose-menu-mapdialog{
  background-color: #e0e0e0 !important;
}
button:focus .btn-backdialog{
  background-color: #e0e0e0 !important;
}
.btn-choose-dialog-viewer-header:focus{
 background-color: #e0e0e0 !important;
}
/* .container-mobile-header button:focus{
  background-color: #e0e0e0 !important;
} */

.text-color-white{
  color: #e0e0e0 !important;
}

.btn-choose-menu-mapdialog {
  /* left: calc(100% - 42px); */
  margin-bottom: 6px !important;
}

.mapDialog-ui {
  top: -20px;
  flex-direction: row !important;
}

.adjust-img-btn_back_portrait{
  position: absolute;
}

.btn-backdialog button:focus {
  background-color: #e0e0e0 !important;
}

.adjust-img-btn_menu_portrait{
  align-self: flex-end;
}
.adjust-img-btn_menu_lanscape{
  order: 1;
}

.viewer-dialog-title {
  width: calc(100% - 115px);
  text-align: center;
}
.viewer-dialog-title h6 {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.viewer-dialog-title {
  width: calc(100% - 115px);
  text-align: center;
}
.viewer-dialog-footer {
  width: calc(100% );
  text-align: right;
}
@media screen and (orientation:portrait) {
  .dialog-viewer-header-update{
    padding: 16px 12px !important;
  }
}

@media screen and (orientation:landscape) {
  .dialog-viewer-header-update-sp{
    padding: 2px 12px !important;
    touch-action: manipulation;
  }
  .dialog-viewer-header-update{
    padding: 16px 12px !important;
  }
}
.dialog-viewer-header-container{
  padding: 0px 12px !important;
}
.dialog-viewer-header-title h6{
  font-size: 25.5px !important;
}

.dialog-btn-adjust-img{
  margin-right: 12px !important;
}
