.axios-error {
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiTypography-root.error-title {
  font-size: 20rem;
  line-height: 20rem;
  color: #f0f0f0;
}

.MuiTypography-root.error-subtitle {
  font-size: 0.8rem;
}
