.sites-staft {
  height: 100%;
}

.pagination {
  border: 1px solid rgba(240, 240, 240, 1);
}

.pagination button:not(:last-child) {
  border-right: 1px solid rgba(240, 240, 240, 1);
}

.pagination button span {
  color: #2b57d0;
}

button.MuiButton-textSecondary:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.image-content.MuiPaper-root {
  box-shadow: none;
  padding: 0;
}

.overlay-image.MuiPaper-root {
  box-shadow: none;
  background-color: transparent;
}

.overlay-image div {
  background-color: transparent;
}

.overlay-image .image-name,
.overlay-image button {
  color: #fff;
}

.overlay-image .image-name {
  padding-left: 0;
}

.overlay-image button {
  font-size: 0.7em;
}

.phone {
  color: black;
}

.staff-data-row {
  height: 53px;
}
