.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  /* background: rgba(255, 255, 255, 1); */
}

.loadingCircular {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.root {
  display: "flex";
  position: "relative";
}
