.shop-customer-info tr {
  border-bottom: none;
  padding-bottom: 15px;
}

.shop-customer-info td {
  padding: 0;
}

.shop-customer-info td {
  font-family: "M PLUS 1p", sans-serif;
  font-size: 1rem;
  border-bottom: none;
  padding-bottom: 15px;
}

.shop-customer-info .MuiGrid-grid-md-6 {
  padding: 0 10px;
}

.shop-customer-info .MuiTableRow-root td:first-child {
  width: 140px;
}

.shop-customer-info .MuiTableRow-root td:last-child {
  word-break: break-all;
}

.shop-customer-tab {
  margin-top: 30px;
}

.MuiCard-root.shop-customer-box-lending {
  box-shadow: none;
  width: 15%;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin-bottom: 15px;
}

.MuiCard-root.shop-customer-box-info {
  box-shadow: none;
  width: 100%;
  background-color: #f0f0f0;
  padding: 20px 30px;
  border-radius: 10px;
  border-radius: 10px;
}

.MuiGrid-root.lending-title {
  width: 100%;
  text-align: center;
}

.contract-history,
.shop-robot {
  margin-top: 30px;
}

.pd-10 {
  padding: 10px;
}
