.status-yellow {
  background-color: #ebd844;
}

.status-green {
  background-color: #6fa332;
}

.status-yellow-green {
  background-color: #8fc31f;
}

.status-grey {
  background-color: #808080;
}

.status-black {
  background-color: #000000;
}

.status-red {
  background-color: #ff0000;
}

.status-block{
  display: inline-flex;
  align-items: center;
}

.status {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  margin-right: 10px;
}

.status-small{
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin-right: 10px;
} 

.disconnect-time{
  color: #808080;
  font-size: 0.6rem;
}
span.padding-first-td,
span.padding-first-th {
  padding-left: 55px;
}

span.MuiTableCell-root.padding-last-td {
  padding-right: 40px;
}

.reset-link {
  text-decoration: none;
  color: #333;
}

.reset-link button:focus {
  background-color: transparent;
}

.log-list-wrap {
  max-width: 240px;
  width: 100%;
  height: calc(100vh - 80px);
  padding-bottom: 47px;
  border-right: 1px solid rgba(240, 240, 240, 1);
  box-shadow: 10px 0px 10px -10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.log-list-wrap .log-list {
  width: 100%;
  position: static;
  overflow-y: auto;
  height: 100%;
}

.log-content {
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: auto;
  padding: 40px;
}

.log-title {
  display: flex;
  white-space: nowrap;
  align-items: center;
  width: calc(100% - 40px);
}

.log-title .title-name {
  margin-right: 55px;
}

.MuiTypography-root.title-name {
  font-weight: bold;
  font-family: "M PLUS 1p", sans-serif;
}

.MuiCard-root.log-box {
  box-shadow: none;
  width: 100%;
  background-color: #f0f0f0;
  padding: 40px;
  border-radius: 10px;
  display: flex;
  border-radius: 10px;
}

.log-box .MuiCardContent-root.log-info {
  padding: 0;
  padding-left: 30px;
}

.log-box .log-image {
  padding-bottom: 75%;
  background-size: contain;
}

.log-box .wrap-image {
  width: 40%;
}

.log-box .log-info {
  width: 60%;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

button.log-button-reset {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  height: inherit;
  color: rgba(22, 47, 80, 1);
}

button.log-button-reset:hover,
button.log-button-reset:focus {
  background-color: transparent;
  box-shadow: none;
}

.MuiTable-root.log-table {
  width: 85%;
}

.log-table-body tr,
.log-table-body tr td {
  border-bottom: none;
  font-size: 1.1rem;
  font-family: "M PLUS 1p", sans-serif;
}

.log-table-body tr td {
  padding: 10px 20px;
  width: 175px;
}

.log-table-body tr:first-child td {
  padding-bottom: 60px;
}

.log-table-body tr td:first-child {
  font-weight: bold;
  width: 200px;
}

.log-table-body tr:last-child td {
  padding-top: 120px;
}

.log-table-body tr:first-child td {
  width: 40%;
}

button.btn-red {
  background-color: #f22735;
  color: #fff;
  transition: 0.3s;
}

button.btn-red:hover {
  color: #f22735;
}

.phone-contact {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f0f0f0;
}

.phone-contact span {
  font-family: "M PLUS 1p", sans-serif;
}

.phone-contact .phone-number {
  font-family: "M PLUS 1p", sans-serif;
  font-size: 1.7rem;
  margin-left: 10px;
}

.block {
  display: block;
}

.MuiFormGroup-root.radio-group-reset {
  flex-wrap: nowrap;
  flex-direction: row;
}

.radio-group-reset .MuiFormControlLabel-root {
  margin-right: 0;
}

.radio-group-reset .MuiFormControlLabel-root:first-child {
  margin-right: 40px;
}

.reset-label .MuiFormControlLabel-label {
  font-family: "M PLUS 1p", sans-serif;
  color: #333;
}

.text-tranform-none {
  text-transform: none;
}

.keep-all {
  word-break: keep-all;
}

.header-robot-logbuild-wrap {
  display: flex;
  align-items: center;
}

.logbuild-tab-info {
  margin-top: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.logbuild-robot-info {
  width: 60%;
  padding-top: 60px;
}

.logbuild-robot-info tr {
  border-bottom: none;
}

.logbuild-robot-info tr:nth-of-type(2) td {
  padding-bottom: 45px;
}

.logbuild-robot-info tr:not(:nth-of-type(2)) td {
  padding-bottom: 15px;
}

.logbuild-robot-info td {
  font-family: "M PLUS 1p", sans-serif;
  font-size: 1rem;
  border-bottom: none;
  padding-bottom: 15px;
}

.logbuild-robot-info td:first-of-type {
  font-weight: bold;
  width: 40%;
}

.sharp-character {
  position: relative;
}

.sharp-character .MuiFilledInput-underline:before,
.sharp-character .MuiFilledInput-underline:after {
  display: none;
}

.sharp-character:before {
  content: "#";
  position: absolute;
  left: -20px;
  bottom: 8px;
}

.text-gray input {
  color: rgb(95, 93, 93);
}
