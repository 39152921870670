.MuiTypography-root.dashboard-title,
button.dashboard-button h6,
button.showmore,
.dialog-confirm .confirm-title {
  font-family: "M PLUS 1p", sans-serif;
}

button.MuiButtonBase-root.radius-3 {
  border-radius: 3px;
}

.dashboard {
  padding: 35px;
}

.MuiTypography-root.mt-20 {
  margin-top: 20px;
}

.MuiTypography-root.lh-1-2 {
  line-height: 1.2;
}

.MuiTypography-root.dashboard-title {
  margin-bottom: 30px;
}

.MuiMenuItem-root.dashboard-sp-item {
  padding-top: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #f0f0f0;
}

.MuiMenuItem-root.dashboard-sp-item:hover {
  background-color: transparent;
}

.MuiTypography-root.dashboard-sp-title {
  padding: 0 15px;
  margin-top: 20px;
}

.dashboard-head {
  margin-bottom: 45px;
}

button.dashboard-button {
  background-color: rgba(22, 47, 80, 1);
  min-width: 250px;
  border-radius: 8px;
  color: #fff;
  transition: 0.3s;
}

button.dashboard-button:hover {
  color: rgba(22, 47, 80, 1);
}

button.showmore {
  color: #2b57d0;
  padding-top: 12px;
  padding-bottom: 12px;
}

button.showmore:hover,
button.showmore:focus {
  background-color: transparent;
}

.dashboard-table {
  background: rgba(240, 240, 240, 1);
  border-radius: 8px;
}

.dashboard-loguibld-table {
  background: rgba(240, 240, 240, 1);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dashboard-loguibld-table a {
  padding: 10px;
}

.confirm-title {
  display: block;
}

.confirm-content button {
  width: 100%;
}

button.confirm-button-green {
  background: #8fc31f;
  color: #fff;
  transition: 0.3s;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: none;
}

button.confirm-button-green:hover {
  color: #8fc31f;
}

button.confirm-button-grey {
  background: #808080;
  color: #fff;
  transition: 0.3s;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-transform: none;
}

button.confirm-button-grey:hover {
  color: #808080;
}

.MuiDialogActions-root.confirm-action {
  justify-content: center;
}

.confirm-action button {
  color: rgba(22, 47, 80, 1);
}

.confirm-action button:hover,
.confirm-action button:focus {
  background: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

button.button-text-blue {
  color: #2b57d0;
}

th.first-column {
  padding-left: 55px;
  width: 50%;
}

.card-item {
  padding: 10px;
  width: 300px;
}

.card-item .card-content {
  border: 4px double #c7c7c7;
  padding: 20px 10px 10px;
  cursor: pointer;
  transition: 150ms ease-in-out;
}

.card-item .card-content:hover {
  transform: translateY(-5px);
}

.card-body p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.image-profile {
  width: 80%;
  height: calc(100vw / 8.5);
  margin: 0 auto;
  max-height: 150px;
  background-color: #c7c7c7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-profile .lazyload-wrapper {
  height: 100%;
}

.image-profile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid #c7c7c7;
}

.card-footer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

button.button-log,
button.button-log-disalbe,
button.button-logWalk,
button.button-logWalk-disable {
  width: 120px;
  min-width: 120px;
  font-size: 9px;
  cursor: pointer;
  text-transform: none;
}

.image-profile img.logo-image {
  width: 100px;
  height: 100px;
  margin: auto 0;
}
