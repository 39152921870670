.grey-color {
  color: rgba(128, 128, 128, 1);
}

.wrap {
  flex-wrap: wrap;
}

.image-list span,
h4.image-title,
h4.onsite-name,
.image-content .image-button,
.sites-tab .tab-button,
.image-wrap .image-cap {
  font-family: "M PLUS 1p", sans-serif;
}

button.button-log.padding-x {
  padding: 0 20px;
}

.sites-right .image-list {
  width: 100%;
  position: static;
  overflow-y: auto;
  height: 100%;
}

.sites-right .image-list-wrap {
  max-width: 240px;
  width: 100%;
  height: calc(100vh - 80px);
  padding-bottom: 47px;
  border-right: 1px solid rgba(240, 240, 240, 1);
  box-shadow: 10px 0px 10px -10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.image-list .clip-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.image-list-item {
  border-bottom: 1px solid rgba(240, 240, 240, 1);
}

div.image-list-item:hover,
div.image-list-item:focus,
div.image-list-item.Mui-selected {
  background-color: rgba(240, 240, 240, 1);
}

h4.image-title {
  font-weight: 700;
}
h4.onsite-name {
  font-weight: 700;
}

.image-content {
  padding: 35px;
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.image-content.image-content-sp-padding {
  padding: 13px 15px;
}

.image-content button.edit-button {
  font-size: 0.7rem;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
  margin-left: 25px;
  color: rgba(22, 47, 80, 1);
}

.image-content .edit-button svg {
  width: 0.7em;
}

.sites-tab {
  width: 100%;
  margin-top: 22px;
}

.sites-tab .tabs span.MuiTabs-indicator {
  background-color: rgba(22, 47, 80, 1);
}

.sites-tab .tab-button {
  color: #808080;
  border-bottom: 2px solid #f0f0f0;
}

.sites-tab .tab-button:focus {
  background-color: transparent;
}

.sites-tab .tab-button.Mui-selected {
  color: rgba(22, 47, 80, 1);
}

.sites-tab .first-tab {
  padding: 10px 0;
}

button.margin-left {
  margin-left: 10px;
}

.image-wrap .image-item:not(:last-of-type) {
  margin-bottom: 45px;
}

.image-wrap .image-cap {
  font-weight: 700;
  margin-bottom: 15px;
}

.button-bottom {
  margin-top: 15px;
}

.image-wrap img {
  width: 100%;
  height: calc(100vw / 11);
  object-fit: cover;
  overflow: hidden;
  display: inline-block;
}

.second-tab .add-image {
  padding: 10px 0;
}

.second-tab .group-button button {
  min-width: auto;
}

.dialog-button-remove {
  margin-left: auto !important;
}

button.dialog-button-complete,
button.dialog-button-remove {
  font-size: 0.7rem;
  color: #f22735;
}

.button.dialog-button-complete:focus,
.dialog-button-remove:focus {
  background-color: transparent;
}

.button.dialog-button-complete svg,
.dialog-button-remove svg {
  width: 0.7em;
}

.date-picker {
  width: 100%;
}

.date-picker button:focus {
  background-color: transparent;
}

.reset-select {
  background-color: rgba(0, 0, 0, 0.09);
}

.reset-select:hover {
  background-color: rgba(0, 0, 0, 0.13);
}

.reset-select .MuiSelect-selectMenu {
  padding-left: 12px;
}

.reset-select .MuiSelect-selectMenu:focus {
  background-color: transparent;
}

.reset-select > label {
  transform: translate(12px, 10px) scale(0.75);
}

.reset-select > label + div {
  margin-top: 20px;
}

.reset-select .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-width: 1px;
}

.MuiTypography-root.tab-place-title {
  display: block;
  font-family: "M PLUS 1p", sans-serif;
  color: #3f51b5;
  padding: 5px 10px;
}

button.mr-15,
.MuiTypography-root.mr-15,
li.mr-15 {
  margin-right: 15px;
}

.tab-content {
  width: 100%;
}

.sites-image-sp .worker-sp{
  width: calc(100% / 3) !important;
}

.sites-image-sp .tab-button {
  border-bottom: 2px solid #f0f0f0;
  color: #808080;
  width: calc(100% / 5);
  max-width: none;
}

@media screen and (min-width: 600px) {
  .sites-image-sp .tab-button {
      min-width: 114px;
      border-bottom: 2px solid #f0f0f0;
      color: #808080;
      max-width: none;
    }
  }

.sites-image-sp .tab-button.Mui-selected {
  color: rgba(22, 47, 80, 1);
}

.sites-image-sp .tab-button:focus {
  background-color: transparent;
}

.sites-image-sp .MuiTabs-indicator {
  background-color: rgba(22, 47, 80, 1);
}

.sites-image-sp .tabs {
  margin-bottom: 20px;
}

.image-wrap-sp .image-cap-sp {
  font-weight: bold;
  margin-bottom: 10px;
  font-family: "M PLUS 1p", sans-serif;
  padding-left: 10px;
}

.image-wrap-sp .image-item {
  margin-bottom: 20px;
}

.image-wrap-sp .image-item img {
  width: 100%;
  height: calc(100vw / 4.5);
  object-fit: cover;
  overflow: hidden;
  display: inline-block;
}

button.fab-button-sp {
  box-shadow: none;
  position: fixed;
  bottom: 20px;
  right: 15px;
  z-index: 300;
}

/* button.button-add-sp {
  box-shadow: none;
  background-color: rgba(22, 47, 80, 1);
  position: fixed;
  bottom: 20px;
  left: calc(25% - 20px);
} */

button.button-add-sp {
  box-shadow: none;
  background-color: rgba(22, 47, 80, 1);
  position: fixed;
  bottom: 20px;
  right: 15px;
  z-index: 300;
}
.z-index-300{
  z-index: 300;
}
button.fab-button-logwalk-sp {
  box-shadow: none;
  position: fixed;
  right: calc(25% + 17px);
  transform: translateX(50%);
}
.logwalk-sp-right{
 left: calc(100% - 105px) !important;
}
.button-logWalk-sp-center {
  right: calc(32% + 13px) !important;
}

.button-add-sp label {
  line-height: normal;
}

.tab2-content-sp {
  margin-top: -20px;
}

/* Lazyload */
div.lazyload-wrapper {
  display: inherit;
}

div.placeholder-image {
  display: inline-block;
  width: calc(100% / 6);
  height: calc(100vw / 11);
  background-color: #e0e0e0;
  background-image: url(../img/logo.svg);
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 5px;
}

div.placeholder-image-sp {
  display: inline-block;
  width: calc(100% / 3);
  height: calc(100vw / 4.5);
  background-color: #e0e0e0;
  background-image: url(../img/logo.svg);
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 5px;
}

div.div-photo {
  display: inline-block;
  width: calc(100% / 6);
}

div.div-photo-sp {
  display: inline-block;
  width: calc(100% / 3);
}

@media screen and (max-width: 959px) {
  
.dialog-complete-delete-tab {
    flex-direction: column;
  }
}