.search-box {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    background-color: #007CFF;
    
  }
  .search-box > button  {
    width: 60px;
    height: 45px;
    background-color: #007CFF;
  }

 .search-box-input {
    display: flex;
    flex-grow: 1;
    height: 45px;
    overflow: hidden;
    background-color: #007CFF;
   
  }

  .search-box-input > input {
    width: 100%;
    height: 45px;
    padding: 12px 6px 12px 40px;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 20px;
    margin: 0px;
    border-radius: 4px;
    background-color: white;
  }
  .search-box-input > span {
    position: absolute;
    font-size: 30px;
    width: 24px;
    height: 24px;
    left: 4px;
    top: 2px;
  }
 .list-mention-box {
    overflow-y: auto;
    padding: 14px 0px 20px 10px;
    white-space: nowrap;
  }
 .dialog-mention-item {
    padding: 6px 11px 0px 0px;
    display: inline-block;
    position: relative;
    
  }
  .dialog-mention-item > div {
    padding: 8px 15px;
    font-size: 15px;
    line-height: 20px;
    border-radius: 18px;
    background-color: white;
    color: #00356D;
  }
  .dialog-mention-item > span {
    display: block;
    width: 20px;
    height: 20px;
    font-size: 20px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    background-color: #C7C7C7;
    cursor: pointer;
    line-height: 20px;
  }
 .mentions-box {
    border: 1px solid #BEBEBE;
    border-radius: 4px;
    display: flex;
    justify-content: start;
    flex-wrap: nowrap;
    min-height: 45px;
    max-height: 90px;
    overflow-x: auto;
    padding: 12px;
    cursor: pointer;
    background-color: rgb(248, 249, 249);
  }
 .filter-mention-box {
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    background-color: #F8F9F9;
    max-height: 350px;
    overflow-y: auto;
  }
 .mention-item {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .mention-item :hover {
    background-color: #F5F5F5;
  }

  .chat-box-input{
    border: 1px solid #bfb3b3;
    width: 400px;
    margin: auto 0;
    padding: 17px;
    font-size: 13px;
    overflow: hidden;
    border-radius: 5px 0 0 5px;
    margin-left: 5px;
    background-color: #fff;
    
  }

  .chat-box-input.chat-box-input-tag{
    color: red;
  }

  .chat-box-container{
    border: 1px solid #999;
    border-radius: 5px;
    position: fixed;
    left: 548px;
    background-color: #FFF;
    z-index: 9999;
    /* padding-left: 5px; */
  }

  .chat-box-container.chat-box-info:hover{
    background-color: #f7cccc;
  }
  .chat-box-container.icon-avatar-boxchat{
    border-radius: 50%;
    max-height: 100%;
  }
  .chat-box-container.chat-box-info{
    display: flex;
    flex-wrap: wrap;
    height: 30px;
    margin: 2px 0;
   
  }

  .chat-box-container.chat-box-info .chat-box-name-user{
    margin: auto 5px;
    width: 190px;
  }
  .chat-box-container.chat-box-info.chat-box-imfor-wrap-img{
    max-height: 100%;
  }

  .chat-box-input-tag-user{
    color: #3f51b5;
  }

  .chat-box-input-tag-nomal{
    color: black;
  }

  .meintion-user-select{
    color: blue;
    background: gray;
  }

  .meintion-user-non-select{
    color: black;
    background: white;
  }