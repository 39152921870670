@charset "UTF-8";

/* ==========================================================
 html5 elements
========================================================== */
article,
aside,
figure,
figcaption,
footer,
header,
nav,
section {
  display: block;
}
/* ==========================================================
 reseting
========================================================== */
body {
  line-height: 1.6;
  color: #333333;
  font-size: 14px;
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}
body,
input,
textarea,
select,
button {
  font-family: "M PLUS 1p", sans-serif !important;
  /* 明朝フォント
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "游明朝体", "Yu Mincho", YuMincho, serif;*/
}
body,
div,
pre,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
th,
td,
button,
figure,
figcaption {
  margin: 0;
  padding: 0;
}
input,
textarea {
  margin: 0;
  font-size: 100%;
}
textarea {
  resize: none;
}
button {
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font-size: 100%;
  color: inherit;
  cursor: pointer;
  -webkit-appearance: none;
}
label {
  cursor: pointer;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}
fieldset,
img {
  border: 0;
}
img {
  height: auto;
  vertical-align: top;
}
address,
caption,
code,
em,
th {
  font-style: normal;
  font-weight: normal;
}
ol,
ul {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong,
em {
  font-size: 100%;
  font-weight: normal;
}

sup,
sub {
  font-size: 63%;
}
sup {
  vertical-align: top;
}
sub {
  vertical-align: baseline;
}
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
/* ==========================================================
 base Link
========================================================== */
a {
  color: #f00;
  text-decoration: none;
}
a:hover,
a:active,
a:focus {
  text-decoration: underline;
}
/* ==========================================================
 clearfix
========================================================== */
.clearfix:before,
.clearfix:after,
.contents:before,
.contents:after {
  content: "";
  display: table;
}
.clearfix:after,
.contents:after {
  clear: both;
}
/* For IE 6/7
---------------------------------------------------------- */
.clearfix,
.contents {
  *zoom: 1;
}
/* ==========================================================
 layout
========================================================== */
html {
  overflow-y: scroll;
  height: -webkit-fill-available;
}
/* ==========================================================
 wrapper
========================================================== */
.wrapper {
  width: 100%;
}
/* ==========================================================
 header
========================================================== */
.header {
  width: 100%;
}
.header-in {
  width: 960px;
  margin: 0 auto;
  text-align: left;
}
/* ==========================================================
 nav-breadcrumb
========================================================== */
.nav-breadcrumb {
  width: 960px;
  overflow: hidden;
  margin: 0 auto;
  text-align: left;
}
.nav-breadcrumb li {
  float: left;
  white-space: nowrap;
}
/* ==========================================================
 contents
========================================================== */
.contents {
  width: 960px;
  margin: 0 auto;
  text-align: left;
}
.contents-main {
  float: right;
  width: 640px;
}
.contents-sub {
  float: left;
  width: 300px;
}
/* ==========================================================
 footer
========================================================== */
.footer {
  width: 100%;
}
.footer-in {
  width: 960px;
  margin: 0 auto;
  text-align: left;
}
/* section last margin */
section:last-of-type {
  margin-bottom: 0;
}
/* pagetop
---------------------------------------------------------- */
.btn-cmn-pagetop {
  text-align: right;
}

input[type="text"]:focus {
  background-color: #00356d;
}
/* ==========================================================
 is
========================================================== */
/* opacity hover
---------------------------------------------------------- */
.is-opacity {
  -ms-filter: "alpha( opacity=100)";
  opacity: 1;
}
.is-opacity:hover {
  -ms-filter: "alpha( opacity=70)";
  filter: alpha(opacity=70);
  opacity: 0.7;
}
/* css animation
---------------------------------------------------------- */
.is-animate {
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
/* ==========================================================
 style
========================================================== */
.s-fw-n {
  font-weight: normal;
}
.s-fw-b {
  font-weight: bold;
}
.s-ta-l {
  text-align: left !important;
}
.s-ta-r {
  text-align: right !important;
}
.s-va-t {
  vertical-align: top !important;
}
.s-va-m {
  vertical-align: middle !important;
}
.s-va-b {
  vertical-align: bottom !important;
}
.s-ov-h {
  overflow: hidden;
}
.s-clear {
  clear: both;
}
.s-hide {
  display: none;
}
.s-txt-hide {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.s-bg-n {
  background: none !important;
}
.s-mt-00 {
  margin-top: 0 !important;
}
.s-mb-00 {
  margin-bottom: 0 !important;
}
.s-ml-00 {
  margin-left: 0 !important;
}
.s-mr-00 {
  margin-right: 0 !important;
}
.only-tb,
.only-sp,
.no-pc {
  display: none !important;
}
/* tb
---------------------------------------------------------- */
@media screen and (max-width: 1024px) {
  .only-pc,
  .no-tb {
    display: none !important;
  }
  .only-tb,
  .no-pc {
    display: block !important;
  }
  img.only-tb,
  img.no-pc {
    display: inline-block !important;
  }
}
/* sp
---------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .only-pc,
  .no-sp,
  .only-tb,
  img.only-tb {
    display: none !important;
  }
  .only-sp,
  .no-pc,
  .no-tb {
    display: block !important;
  }
  img.only-sp,
  img.no-pc,
  img.no-tb {
    display: inline-block !important;
  }
}
/* ==========================================================
 tool
========================================================== */
.atode {
  padding: 10px;
  border: 2px solid #8c0000;
  background-color: #ff4d4d;
  color: #fff;
  font-size: 30px;
  text-align: center;
}

@media (hover: none) {
  button.dialog-button:hover {
    background-color: rgba(22, 47, 80, 1);
    color: #fff !important;
  }

  button.button-add-sp:hover {
    background-color: rgba(22, 47, 80, 1);
  }

  button.button-sp:hover {
    background-color: rgba(22, 47, 80, 1);
  }

  button.confirm-button-grey:hover {
    background-color: #808080;
    color: #fff !important;
  }

  button.confirm-button-green:hover {
    background-color: #8fc31f;
    color: #fff !important;
  }
}

@media (pointer: coarse) {
  button.dialog-button:hover {
    background-color: rgba(22, 47, 80, 1);
    color: #fff !important;
  }

  button.button-add-sp:hover {
    background-color: rgba(22, 47, 80, 1);
  }

  button.button-sp:hover {
    background-color: rgba(22, 47, 80, 1);
  }

  button.confirm-button-grey:hover {
    background-color: #808080;
    color: #fff !important;
  }

  button.confirm-button-green:hover {
    background-color: #8fc31f;
    color: #fff !important;
  }
}

.MuiFormControl-root.MuiTextField-root.pdr-50 input {
  padding-right: 50px;
}

ul {
  outline: none;
}

.m-0 {
  margin: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.m-auto {
  margin: auto !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.mx-5 {
  margin: 0 5px !important;
}

.my-5 {
  margin: 5px 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.mx-10 {
  margin: 0 10px !important;
}

.my-10 {
  margin: 10px 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.m-20 {
  margin: 20px !important;
}

.mx-20 {
  margin: 0 20px !important;
}

.my-20 {
  margin: 20px 0 !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.p-0 {
  padding: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pb-10 {
  padding-bottom: 10px;
}

.text-10 {
  font-size: 10px !important;
}

.date-range {
  display: inline-flex;
  flex-direction: column;
  width: calc(50% - 20px) !important;
}

.date-range button {
  padding: 0 !important;
}

.date-range button svg {
  width: 0.8em !important;
}

@media screen and (max-width: 600px) {
  .date-range {
    width: 100% !important;
  }

  div.date-range + div.date-range {
    margin-left: auto !important;
  }

  div.date-range + div.date-range::before {
    content: "" !important;
    top: 0 !important;
    left: 0 !important;
  }
}

.text-center {
  text-align: center !important;
}

.color-red {
 color: red !important;
}

.dialog {
  overflow-y: unset !important;
}

.MuiFab-root.close-button {
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: #fff;
  box-shadow: none;
}

.MuiFab-root.close-button-2 {
  position: absolute;
  top: -20px;
  right: -30px;
  background-color: #fff;
}

.MuiFab-root.close-button:hover,
.MuiFab-root.close-button-2:hover {
  background-color: #fff;
}

.MuiFab-root.close-button .MuiSvgIcon-root,
.MuiFab-root.close-button-2 .MuiSvgIcon-root {
  color: rgba(22, 47, 80, 1);
}

.MuiGrid-root.image-div {
  height: 334px;
  background-color: #eeeeee;
  border-radius: 0.2rem;
  outline: 3px dashed rgb(52, 110, 185);
  outline-offset: -10px;
}
.MuiGrid-root.image-title {
  text-align: left;
  height: 30px;
  font-size: 0.8rem;
}
.MuiGrid-root.image-title .MuiTypography-subtitle1 {
  font-size: 0.8rem;
}
.MuiGrid-root.onsite-name {
  text-align: left;
  height: 30px;
  font-size: 0.8rem;
}
.MuiGrid-root.onsite-name .MuiTypography-subtitle1 {
  font-size: 0.8rem;
}

.MuiGrid-root.drop-area {
  position: relative;
  margin: 0;
  height: calc(100% - 30px);
}

.MuiGrid-root.drop-area:hover {
  background-color: #eeeeee;
}

@media screen and (max-width: 960px) {
  .MuiGrid-root.image-div {
    height: 200px;
  }
}

.upload-icon {
  width: 50px;
  height: 50px;
  background: url(../img/file_upload_icon.png) no-repeat center center;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.drop-message {
  text-align: center;
  color: rgba(22, 47, 80, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image-form {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
}

.delete-button {
  position: absolute !important;
  right: 5px;
  top: -20px;
  background-color: #fff;
}

#image-file {
  object-fit: contain;
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

.width-auto {
  width: auto;
}

.text-transform-none {
  text-transform: none !important;
}

.tab-height {
  height: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.card-title {
  font-size: 26px !important;
  height: 73px;
  padding: 10px;
  display: block !important;
  display: -webkit-box !important;
  max-width: 100%;
  margin: 0 auto !important;
  line-height: 1.24 !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-over-wrap {
  overflow-wrap: anywhere;
  word-break: break-word;
}

div.date-range + div.date-range {
  margin-left: 40px;
  position: relative;
}

div.date-range + div.date-range::before {
  position: absolute;
  content: "～";
  top: 36px;
  left: -28px;
  font-family: "M PLUS 1p", sans-serif !important;
}

.error-resize {
  width: auto !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.guid-message {
  color: red;
  font-size: 11px;
}
.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block;
}

.icon-transparent {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #162f50 !important;
}

.display-flex {
  display: flex;
}

.anim-box.zoomin.is-animated {
  animation: zoomIn 0.8s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

button.confirm-button-blue {
  background-color: rgba(22, 47, 80, 1) !important;;
  color: #fff !important;;
  transition: 0.3s;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: none;
}

/* button.confirm-button-blue:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(22, 47, 80, 1);
}

button.confirm-button-blue:focus {
  background-color: rgba(22, 47, 80, 1);
  color: #fff;
} */

button.confirm-button-sliver {
  background-color: rgb(230, 230, 230) !important;
  color: rgba(22, 47, 80, 1) !important;;
  transition: 0.3s;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: none;
}

button.confirm-button-outline {
  background-color: #fff ;
  color: rgba(22, 47, 80, 1);
  transition: 0.3s;
  margin-bottom: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 2px solid rgba(22, 47, 80, 1) !important;
  text-transform: none;
}

button.confirm-button-delete {
  background-color: red ;
  color: #fff;
  transition: 0.3s;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: none;
}

/* button.confirm-button-sliver:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(22, 47, 80, 1);
} */

.btn-disable {
  background-color: #cecece !important;
  color: rgba(22, 47, 80, 1) !important;
}

.label-highlight {
  color: #ff0000;
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.text-left {
  text-align: left !important;
}
.text-left-normal {
  text-align: left;
}
.text-right {
  text-align: right !important;
}
.text-right-normal {
  text-align: right;
}

.display-block {
  display: block !important;
}

.wrap-text-multi-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

div.placeholder-card-image {
  background-image: url(../img/card-image.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  background-size: cover;
  margin: auto 0;
}

.search-input {
  height: 25px;
  width: 205px;
  border-radius: 15px;
  padding: 0 10px 0 5px;
  border: 1px solid rgba(22, 47, 80, 1) !important;
  display: inline-flex;
}

.search-input input {
  border: none;
  width: 160px;
}

.search-input input:focus {
  background-color: #fff !important;
  border-color: none !important;
}

.search-input input:focus-visible {
  border-color: none !important;
  outline: none;
}

@media screen and (min-width: 960px) {
  .page-content {
  }
}

.no-select {
  -webkit-tap-highlight-color: transparent; /* Disable blue highlight when Touch/Press */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
